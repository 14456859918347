import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { postWithAuth } from '~utils/apiUtil';
import { useAuth } from '~hooks';
import './WriterContentGenerator.styles.scss';
import { GENERATE_CONTENT_URL } from '~constants/urls';
import ContentGeneratorHeader from './ContentGeneratorHeader/ContentGeneratorHeader';
import { ContentTextArea } from '~components/ContentTextArea/ContentTextArea';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import { ReactComponent as CustomizeIcon } from '~/assets/customize.svg';
import { ReactComponent as QuotesIcon } from '~/assets/quotes.svg';
import { ReactComponent as FilterIcon } from '~/assets/filter.svg';
import { ReactComponent as SwapIcon } from '~/assets/swap.svg';
import { ReactComponent as ExpandIcon } from '~/assets/expand.svg';
import { ReactComponent as ShortenIcon } from '~/assets/shorten.svg';

export const WriterContentgenerator = () => {
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { token } = useAuth();

    const location = useLocation();
    const { dataParams } = location.state || {}; // Assuming params are passed via location.state
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const topic = dataParams?.topic;

    console.log('generate content url', GENERATE_CONTENT_URL);

    const onTextUpdate = (text: string) => {};

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await postWithAuth(
                    GENERATE_CONTENT_URL,
                    dataParams,
                    token as string,
                );
                console.log('response in generate ----->', response.data.data);
                if (response.status === 200) {
                    const formattedText = response.data.data
                        .replace(/\\n/g, '\n') // Replace \n with actual new lines
                        .replace(/"/g, ''); // Remove double quotes if needed
                    setData((prevData) => prevData + formattedText); // Append new data
                }
            } catch (err) {
                setError(err as any);
            } finally {
                setLoading(false);
            }
        };

        if (dataParams) {
            // Ensure params are available before making the request
            fetchData();
        }
    }, [dataParams, token]); // Dependency array to refetch if parameters change

    // Scroll the textarea to the bottom when new data is added
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    }, [data]); // Trigger on data change

    if (loading)
        return (
            <>
                <header>
                    <h1 className="dashboard-title">
                        👋Welcome back to StudyBuddy!
                    </h1>
                </header>
                <p>Loading...</p>
            </>
        );
    if (error)
        return (
            <>
                <header>
                    <h1 className="dashboard-title">
                        👋Welcome back to StudyBuddy!
                    </h1>
                </header>
                <p>Error: Please try again!</p>
            </>
        );

    return (
        <>
            <header>
                <h1 className="dashboard-title">
                    "{topic}""
                </h1>
            </header>
            <div className="content-generator-container">
                <ContentGeneratorHeader />

                <div className="content-humanizer-parent main">
                    <div className="content-humanizer-header">
                        <h2>
                           "{topic}"
                        </h2>
                    </div>
                    <div className="content-humanizer-body">
                        <ContentTextArea
                            onTextChange={onTextUpdate}
                            isInDetectMode={true}
                            isHighlighted={true}
                            checkAiText={data}
                        />
                    </div>
                    <div className="content-humanizer-footer">
                        <button className="humanizeButton">
                            <NinjaIcon />
                            Humanize All Text
                        </button>
                        <button className="white-button">
                            <CustomizeIcon />
                            Customize{' '}
                        </button>
                        <button className="white-button">
                            <QuotesIcon />
                            Cite{' '}
                        </button>
                        <button className="white-button">
                            <FilterIcon />
                            Personalize{' '}
                        </button>
                        <button className="white-button">
                            <SwapIcon />
                            Paraphrase{' '}
                        </button>
                        <button className="white-button">
                            <ExpandIcon />
                            Expand{' '}
                        </button>
                        <button className="white-button">
                            <ShortenIcon />
                            Shorten{' '}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
