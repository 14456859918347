import React from 'react';
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import TurboCrossSellDesktop from '~/assets/onboarding-turbo-crossSell-desktop.svg';
import TurboCrossSellMobile from '~/assets/onboarding-turbo-crossSell-mobile.svg';
import { useTranslation } from 'react-i18next';

const TurboModeCrossSell = (props: any) => {
    const { onRightButtonClick, increaseStep } = props;
    const { isMobile, isTablet } = useScreenSize();
    const { t } = useTranslation();
    const { user, token, planStatus } = useAuth();
    const { infoSnackbar, successSnackbar, errorSnackbar } = useSnackbar();

    // TODO: Add turbo mode subscription plan logic, if upgraded to turbo, finish onboarding and return to dashboard
    const customerId = user?.data?.attributes['custom:stripeCustomerID'];
    // const onUpgradeToTurboBtnClick = async () => {
    //     // setLoading(true);
    //     infoSnackbar(`${t('upgrading_sub')}...`);
    //     const res = await semesterPlanUpgrade(token, customerId);
    //     logger.info('semester subscription result >> ' + res);
    //     if (res === 'Success') {
    //         await emitSemeseterPlanEvent();
    //         successSnackbar('Semester subscription started successfully');
    //         window.location.reload();
    //     } else {
    //         errorSnackbar(
    //             'Something went wrong, try again. This is likely because your bank declined the charge, please try again with a different card.',
    //         );
    //         // setLoading(false);
    //     }
    // };
    const handleUpgradeToTurbo = () => {
        // onUpgradeToTurboBtnClick();
        onRightButtonClick();
    };

    return (
        <div>
            <img
                style={{ objectFit: 'cover' }}
                src={isMobile ? TurboCrossSellMobile : TurboCrossSellDesktop}
                alt="turbo-crossSell"
            />
            <button onClick={handleUpgradeToTurbo} className="crossSell-btn">
                {'Upgrade'}
            </button>
            <p className="crossSell-warning-text">
                {
                    'Notice: This is a one-time offer. It will NEVER be available again'
                }
            </p>
            <button
                onClick={onRightButtonClick}
                className="crossSell-cancel-btn"
            >
                {'No, I’ll pass on saving time and effort.'}
            </button>
        </div>
    );
};

export default TurboModeCrossSell;
