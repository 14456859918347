import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface HowItWorksItemProps {
    title: string;
    subtitle: string;
    content: string;
    videoSrc: string;
    highLightedComponent?: 'title' | 'subtitle';
    initialState?: boolean;
}

export const HowItWorksItem = ({
    title,
    subtitle,
    content,
    videoSrc,
    highLightedComponent,
    initialState,
}: HowItWorksItemProps) => {
    const [isActive, setIsActive] = useState(initialState);

    const { t } = useTranslation();

    const handleToggle = () => {
        setIsActive(!isActive);
    };

    const isSubtitleHiglighted = highLightedComponent === 'subtitle';

    return (
        <div className="howItWorksItem" onClick={handleToggle}>
            <div className="itemBetweenContent">
                <div className="itemHeader">
                    {isSubtitleHiglighted ? (
                        <h3>
                            {t(title)} <span>{t(subtitle)}</span>
                        </h3>
                    ) : (
                        <h3>
                            <span>{t(title)}</span> {t(subtitle)}
                        </h3>
                    )}
                </div>

                <div className={`${isActive ? 'active' : ''}`}>
                    <img
                        src={'/assets/icons/chevron-down-dark-blue.svg'}
                        alt="How It works toggle"
                    />
                </div>
            </div>
            <div
                className={`howItWorksAccordianContainer ${isActive ? 'active' : ''}`}
            >
                <div>
                    <p dangerouslySetInnerHTML={{ __html: content }}></p>
                    <video
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}
                        id="screenshotVideo"
                        className="videoContent"
                        preload="auto"
                        autoPlay
                        muted
                        loop
                        playsInline
                    >
                        <source src={videoSrc} type="video/mp4" />
                    </video>
                </div>
            </div>
            <hr className="howItWorksItemDivider" />
        </div>
    );
};
