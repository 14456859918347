import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Assets, icons } from '~constants';
import { onboardingSteps } from '../Onboarding.constants';
import type { OnboardingStepProps } from './OnboardingStep.types';
import type { FC } from 'react';

import './OnboardingStep.styles.scss';
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import { TextParser } from '~components/TextParser';
// import { FeedbackPopup } from '~components/Modals/FeedbackPopup';
// import { TutorialWithButtonsPopup } from '~components/Modals/TutorialWithButtonsPopup';
// import { SubmitQuesFormPopup } from '~components/Modals/SubmitQuesFormPopup';
import { parseTextWithStrongTag } from '~utils/parseDynamicText';
import { logger } from '~utils';
import SemesterDealUpsell from '../SemesterDealUpsell';
import TurboModeCrossSell from '../TurboModeCrossSell';

export const OnboardingStep: FC<OnboardingStepProps> = (props: any) => {
    const { isMobile, isTablet } = useScreenSize();
    const { step, onLeftButtonClick, onRightButtonClick, increaseStep } = props;
    const { user } = useAuth();
    const [referralUrl, setReferralUrl] = useState<string>('');
    const { successSnackbar } = useSnackbar();
    const { t } = useTranslation();
    console.log('step now is', step);

    const isShowExtenstionBtn = step === 1 && !isMobile && !isTablet;

    const isShowDoneBtn = step === onboardingSteps.length;

    const isShowSemesterUpsell = step === 4;
    const isShowTurboCrossSell = step === 5;

    useEffect(() => {
        let code = user?.data?.attributes['custom:stripeCustomerID'];
        if (!code || !code.startsWith('cus_')) {
            logger.error(
                'No Stripe Customer ID found when trying to create referral code' as any,
            );
        } else {
            code = code.replace('cus_', '');
            setReferralUrl(`https://studybuddy.gg?ref=${code}`);
        }
    }, [user]);

    return (
        <div className="onboarding-steps-container">
            {onboardingSteps.map((onboardingStep) => {
                return (
                    <div
                        className={`onboarding-step ${
                            onboardingStep.id === step ? 'active' : ''
                        } ${step === 3 ? 'step_3' : ''}`}
                        key={onboardingStep.id}
                    >
                        <h1
                            dangerouslySetInnerHTML={{
                                __html: parseTextWithStrongTag(
                                    onboardingStep.title,
                                ),
                            }}
                        ></h1>
                        {onboardingStep.bulletPoints && (
                            <ul>
                                {onboardingStep.bulletPoints.map((point) => (
                                    <li
                                        key={point}
                                        dangerouslySetInnerHTML={{
                                            __html: parseTextWithStrongTag(
                                                point,
                                            ),
                                        }}
                                    ></li>
                                ))}
                            </ul>
                        )}
                        {onboardingStep.subBulletPoints && (
                            <ul>
                                {onboardingStep.subBulletPoints.map(
                                    (subBulletPoint) => (
                                        <li key={subBulletPoint.title}>
                                            <strong>
                                                {subBulletPoint.title}
                                            </strong>
                                            <ul>
                                                {subBulletPoint.bulletPoints.map(
                                                    (point) => (
                                                        <li
                                                            key={point}
                                                            dangerouslySetInnerHTML={{
                                                                __html: parseTextWithStrongTag(
                                                                    point,
                                                                ),
                                                            }}
                                                        ></li>
                                                    ),
                                                )}
                                            </ul>
                                        </li>
                                    ),
                                )}
                            </ul>
                        )}

                        {/* <p>{onboardingStep.subTitle}</p> */}

                        <div
                            className={`onboarding-step__image ${step === 3 ? 'step_3' : ''}`}
                        >
                            {onboardingStep.assetType === 'video' && (
                                <video controls>
                                    <source src={onboardingStep.assetPath} />
                                </video>
                            )}
                            {onboardingStep.assetType === 'image' && (
                                <img src={onboardingStep.assetPath} />
                            )}
                        </div>
                    </div>
                );
            })}

            {!isShowSemesterUpsell && !isShowTurboCrossSell && (
                <div
                    className={`onboarding-buttons ${step === 3 ? 'step_3' : ''}`}
                >
                    {isShowExtenstionBtn ? (
                        <>
                            <button
                                className="cta-btn transparent"
                                onClick={onLeftButtonClick}
                            >
                                <img src={icons.chromeBlue} alt="open" />
                                Download Extension
                            </button>
                            <button className="cta-btn" onClick={increaseStep}>
                                {isShowDoneBtn ? (
                                    <span>Done</span>
                                ) : (
                                    <span>
                                        Next Step
                                        <img
                                            src={icons.rightAngleArrowWhite}
                                            alt="icon"
                                        />
                                    </span>
                                )}
                            </button>
                        </>
                    ) : (
                        <>
                            {!isMobile && !isTablet && !isShowDoneBtn && (
                                <button
                                    className="cta-btn transparent"
                                    onClick={onLeftButtonClick}
                                >
                                    <span>Back</span>
                                </button>
                            )}
                            {!isMobile && !isTablet && isShowDoneBtn && (
                                <button
                                    onClick={() => {
                                        navigator.clipboard
                                            .writeText(referralUrl)
                                            .then(() => {
                                                successSnackbar(
                                                    t('referral_success'),
                                                );
                                            });
                                    }}
                                    className="referral-copy-button"
                                >
                                    <img
                                        src={icons.shareReferral}
                                        alt="share icon"
                                    />
                                    <div className="ReferralCopyButtonText">
                                        Copy Link
                                    </div>
                                </button>
                            )}
                            {/* {isShowDoneBtn && (
                            <button
                                className="cta-btn transparent"
                                onClick={onRightButtonClick}
                            >
                                <span>Done</span>
                            </button>
                        )} */}
                            {/* {!isShowDoneBtn && ( */}
                            <button className="cta-btn" onClick={increaseStep}>
                                <span>
                                    Next Step
                                    <img
                                        src={icons.rightAngleArrowWhite}
                                        alt="icon"
                                    />
                                </span>
                            </button>
                            {/* )} */}
                        </>
                    )}
                </div>
            )}

            {isShowDoneBtn && (
                <p className="not-interested-btn" onClick={onRightButtonClick}>
                    No, I don’t want to help my friends
                </p>
            )}

            {isShowSemesterUpsell && (
                <SemesterDealUpsell
                    increaseStep={increaseStep}
                    onRightButtonClick={onRightButtonClick}
                />
            )}
            {isShowTurboCrossSell && (
                <TurboModeCrossSell onRightButtonClick={onRightButtonClick} />
            )}
            {/* {step === 7 && (
                <TutorialWithButtonsPopup
                    step={step}
                    open={isShowTutorialPopup}
                    onClose={() => {}}
                    increaseStep={increaseStep}
                    onLeftButtonClick={onLeftButtonClick}
                    onRightButtonClick={onRightButtonClick}
                />
            )}
            {step === 8 && (
                <SubmitQuesFormPopup
                    step={step}
                    open={isShowFormPopup}
                    onClose={() => {}}
                    increaseStep={increaseStep}
                    onLeftButtonClick={onLeftButtonClick}
                    onRightButtonClick={onRightButtonClick}
                />
            )} */}
        </div>
    );
};
