import React, { FC, useState } from 'react';

import { AppRoutes, Assets, icons } from '~constants';
import { FancyLoader } from '~components/Loader';
import { emitViralShareEvent } from '~utils/advertisingEvents';
import { handleShare } from '~utils/share';
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import { logger } from '~utils';
import Logo from '../../../assets/studybuddy-logo-popup.svg';
import FreeWeekImg from '../../../assets/free-week-img.svg';
import TiktokBlackLogo from '../../../assets/TiktokLogo_black.svg';
import InstaBlackLogo from '../../../assets/InstaLogo_black.svg';
import SharePopupHeaderDesktop from '~assets/share-popup-header-desktop.svg';
import SharePopupHeaderMobile from '~assets/share-popup-header-mobile.svg';
import './SharePopup.styles.scss';
import { VIRAL_SHARE_TEXT, VIRAL_SHARE_URL } from './SharePopup.constants';
import { giveViralShareCredit } from './SharePopup.functions';
import { useTranslation } from 'react-i18next';

type Props = {
    isShowLogo?: boolean;
    handleIsSharePopup: (show: boolean) => void;
};

export const SharePopup: FC<Props> = (props) => {
    const { isShowLogo, handleIsSharePopup } = props;
    const [isShareActive, setIsShareActive] = useState(false);
    const { user, token } = useAuth();
    const { isMobile, isTablet, isLaptop, isDesktop } = useScreenSize();
    const { successSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const startShare = async () => {
        let forceWait = false;
        setIsShareActive(true);
        emitViralShareEvent();
        handleShare(VIRAL_SHARE_TEXT + ' ' + VIRAL_SHARE_URL)
            .then(async () => {
                const customerId =
                    user?.data?.attributes['custom:stripeCustomerID'];
                await giveViralShareCredit(token, customerId, false);
            })
            .catch((e) => {
                logger.error(e);
                forceWait = true;
                copyToClipboard(null);
            })
            .finally(() => {
                // If failure to open share menu we wait a few seconds to ensure they are sharing
                if (forceWait) {
                    setTimeout(() => {
                        handleIsSharePopup(false);
                        setIsShareActive(false);
                    }, 8 * 1000);
                } else {
                    handleIsSharePopup(false);
                    setIsShareActive(false);
                }
            });
    };

    const copyToClipboard = (event: any) => {
        event?.preventDefault();
        navigator.clipboard.writeText(VIRAL_SHARE_TEXT + ' ' + VIRAL_SHARE_URL);
        successSnackbar('Copied to clipboard!');
    };

    const handleCancel = () => {
        successSnackbar('Welcome to Studybuddy!');
        handleIsSharePopup(false);
    };

    return (
        <div className="share-popup-background">
            <div className="share-popup">
                {/* <div
                    className="share-popup-logo"
                    onClick={() => (window.location.href = AppRoutes.Dashboard)}
                >
                    <img src={Logo} alt="logo" />
                </div>
                {isMobile ? (
                    <h2 className="title">
                        Invite A Friend, <br /> Get A{' '}
                        <img src={FreeWeekImg} alt="free" /> Week
                    </h2>
                ) : (
                    <h2 className="title">
                        Invite A Friend, Get A{' '}
                        <img src={FreeWeekImg} alt="free" /> Week
                    </h2>
                )} */}
                <div style={{ marginBottom: '16px' }}>
                    <img
                        src={
                            isMobile
                                ? SharePopupHeaderMobile
                                : SharePopupHeaderDesktop
                        }
                        alt="share"
                    />
                </div>

                <div className="bottom">
                    <button className="share-btn" onClick={startShare}>
                        {t('copy_link')}
                    </button>

                    <div className="social-container">
                        <a
                            href="https://www.tiktok.com/@getstudybuddy.gg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={TiktokBlackLogo} alt="tiktok" />
                        </a>
                        <a
                            href="https://www.instagram.com/studybuddy.gg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={InstaBlackLogo} alt="instagram" />
                        </a>
                    </div>

                    {isShareActive ? (
                        <i>{t('link_copied')}</i>
                    ) : (
                        <p className="cancel-btn" onClick={handleCancel}>
                            {t('No, I don’t want to help my friends')}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};
