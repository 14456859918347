import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { FC, useState } from 'react';

import type {
    RegisterPageProps,
    SignUpFormData,
    SignUpFormErrors,
} from './Register.types';
import {
    isEmailAlreadyUsedHelper,
    formValidation,
    signUp,
} from './Register.functions';
import { Header } from '~components/Header';
import { RegisterForm } from '~components/RegisterForm';
import { RegisterSidePanel } from '~components/RegisterSidePanel';

import { useScreenSize, useSnackbar } from '~hooks';
import { AppRoutes, Assets, defaultErrorMessage, icons } from '~constants';

import './Register.styles.scss';
import { getCurrentEnvironment, logger } from '~utils';
import { ReviewCarousel } from '~components/ReviewCarousel';
import { Hub } from 'aws-amplify';

const userReviews = [
    Assets.userReviewMobile1,
    Assets.userReviewMobile2,
    Assets.userReviewMobile3,
    Assets.userReviewMobile4,
];

export const Register: FC<RegisterPageProps> = (props) => {
    const { isPopupCheckout, handleCheckoutPage } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<SignUpFormData>({
        email: '',
        password: '',
        confirmEmail: '',
    });
    const [emailAlreadyExist, setEmailAlreadyExist] = useState<
        boolean | undefined
    >(undefined);
    const [formErrors, setFormErrors] = useState<SignUpFormErrors>(null);

    const navigate = useNavigate();
    const { errorSnackbar } = useSnackbar();
    const { isMobile } = useScreenSize();

    const onInputChange = (event: any) => {
        const { name, value } = event.target;
        if (name === 'email' && emailAlreadyExist === true) {
            setEmailAlreadyExist(false);
        }
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
        setFormErrors((prevFormData: any) => ({
            ...prevFormData,
            [name]: '',
        }));
    };

    const onEmailBlur = async (event: any) => {
        const { isProd } = getCurrentEnvironment();
        if (!isProd) {
            return;
        }
        // Inside a try catch since it shouldnt break the app
        try {
            event?.preventDefault();
            const emailValue = event.target.value;
            const emailExists = await isEmailAlreadyUsed(emailValue);
            // event listener for email, abandon cart
            if (emailExists == false && validateEmail(emailValue)) {
                // identify
                (window as any).klaviyo
                    .identify({
                        $email: emailValue,
                    })
                    // track
                    .then(() => {
                        (window as any).klaviyo.push([
                            'track',
                            'Entered Email on Registration',
                            {
                                $event_id: new Date().getTime(),
                            },
                        ]);
                    });
            }
            return emailExists;
        } catch (error) {
            logger.error(error as any);
            return false;
        }
    };

    const isEmailAlreadyUsed = async (email: string): Promise<boolean> => {
        logger.debug('Checking if this email already exists...', email);
        const isEmailExist = await isEmailAlreadyUsedHelper(email);
        if (isEmailExist === true) {
            setEmailAlreadyExist(true);
            setFormErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Email already exists. Please Login instead.',
            }));
        }
        return isEmailExist;
    };

    // https://stackoverflow.com/a/46181
    function validateEmail(email: string): boolean {
        return (
            String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                ) !== null
        );
    }

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        // This if statement is in case the user tries to submit before we can check if the email is duplicate
        const errors = formValidation(formData);
        if (errors) {
            setIsLoading(false);
            setFormErrors(errors);
            return;
        }
        console.log('formData register begins___');
        try {
            setFormErrors(null);
            await signUp(formData);
            console.log('formData register ends___');
            /* if (isPopupCheckout && handleCheckoutPage) {
                    handleCheckoutPage();
                    return;
                } else { */
            /* navigate(AppRoutes.Signin_Redirect, {
                state: { event: 'InitiateCheckout' },
            }); */
            navigate(AppRoutes.Signin_Redirect, {
                state: { event: 'InitiateCheckout' },
            });
        } catch (err: any) {
            setIsLoading(false);
            errorSnackbar(err.message || defaultErrorMessage);
        }
    };

    /* Hub.listen('auth', (data) => {
        console.log('Auth HUB event ___', data.payload);
        if (data.payload.event === 'signIn') {
            console.log('register user redirect ___');
            const userToken = data.payload.data.signInUserSession.idToken.jwtToken;
            navigate(AppRoutes.Signin_Redirect, {
                state: { event: 'InitiateCheckout', userToken: userToken },
            });
        }
    }); */

    return (
        <div className="register-page-container">
            <Row className="register-page">
                <Col className="form-section" lg={4} md={12}>
                    {/* <Header logoAlign="left-relative" /> */}

                    <div
                        className="register-page-logo"
                        onClick={() =>
                            (window.location.href = AppRoutes.Dashboard)
                        }
                    >
                        <img src={icons.graduationBlack} alt="logo" />
                        <h3>
                            Study<span>buddy+</span>
                        </h3>
                    </div>

                    <RegisterForm
                        onChange={onInputChange}
                        onSubmit={onSubmit}
                        formData={formData}
                        formErrors={formErrors}
                        onEmailBlur={onEmailBlur}
                        isButtonLoading={isLoading}
                        isEmailExist={emailAlreadyExist}
                    />
                    <ReviewCarousel userReviews={userReviews} />
                </Col>
                <Col className="info-section" lg={8} md={0}>
                    <RegisterSidePanel isInPopup={true}/>
                </Col>
                {isMobile && <div className="blur-backdrop-left"></div>}
            </Row>
        </div>
    );
};

export default Register;
