import React from 'react';
import BlurBackdropPopup from '~components/Modals/BlurBackdropPopup/BlurBackdropPopup';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import { ContentTextArea } from '~components/ContentTextArea/ContentTextArea';
import CrossIcon from '~/assets/cross.svg';

import './CheckForAi.style.scss';
import AiDetectorFooter from '~pages/WriterDashboard/AiDetector/AiDetectorFooter/AiDetectorFooter';

interface CheckForAiProps {
    showPopup: boolean;
    text: string;
    handleClose: () => void;
    onTextUpdate: (text: string) => void;
    onHumanizeText:()=>void;
}

const CheckForAi: React.FC<CheckForAiProps> = ({
    showPopup,
    handleClose,
    onTextUpdate,
    text,
    onHumanizeText
}) => {
    return (
        <BlurBackdropPopup show={showPopup} handleClose={handleClose}>
            <div className="checkAiContainer">
                <div className="closePopup">
                    <img
                        onClick={handleClose}
                        src={CrossIcon}
                        height={'25px'}
                        width={'25px'}
                        alt="close"
                    />
                </div>
                <div className="checkAiHeader">
                    <span> Waiting for your input... </span>
                    <button className="humanizeButton" onClick={onHumanizeText}>
                        <NinjaIcon />
                        Humanize All Text
                    </button>
                </div>

                <div className="checkAiBody">
                    <ContentTextArea
                        onTextChange={onTextUpdate}
                        isInDetectMode={true}
                        isHighlighted={true}
                        checkAiText={text}
                    />
                </div>
                <div className="checkAiFooter">
                    <AiDetectorFooter percentage={90} status="DETECTED" />
                </div>
            </div>
        </BlurBackdropPopup>
    );
};

export default CheckForAi;
