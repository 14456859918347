import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '~hooks';
import { AppRoutes, icons } from '~constants';
import { writerSteps } from './WriterSteps.constants';
import './WriterSteps.styles.scss';
import { Steps } from './Steps';

export type WriterStepsProps = {
    show: boolean;
    handleClose: () => void;
    handleCTA: () => void;
};

export const WriterSteps: React.FC<WriterStepsProps> = (props) => {
    const [step, setStep] = useState(1);
    const [topic, setTopic] = useState<string | undefined>();
    const [long, setLong] = useState(1);
    const [tone, setTone] = useState<string | undefined>();
    const { isMobile } = useScreenSize();
    const { show, handleClose, handleCTA } = props;
    
    const navigate = useNavigate();

    const handleLeftButtonClick = () => {
        if (step === 1 && !isMobile) {
            navigate(AppRoutes.Home);
            return;
        }
        setStep(step - 1);
    };

    const getWordsLength = (length: any) => {
        console.log('length ---------->', length, typeof(length));
        
        if (length === 1) {
            return 500;
        } else if (length === 3) {
            return 1000;
        } else if (length === 9) {
            return 1500;
        } else {
            return 2000;
        }
    }

    const handleRightButtonClick = async () => {
        if (step < 4) {
            setStep(step + 1);
        } else {
            // Prepare data to pass
            const dataParams = {
                topic,
                length: getWordsLength(long),
                themePick: tone,
            };

            // Navigate with data (e.g., as state)
            navigate(AppRoutes.WriterContentgenerator, { state: { dataParams } });

            // Optionally, you can log or handle the data here
            console.log('Navigating with data', dataParams);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered contentClassName="modalContent">
            <div className="writer-steps-section">
                <h1 className="step-title">{writerSteps[step - 1].stepName}</h1>
                <div className="steps-tab">
                    {writerSteps.map((writerStep) => (
                        <div className="step-container" key={writerStep.id}>
                            <div className={`step-circle ${writerStep.id <= step ? 'active' : ''}`}>
                                <p>{writerStep.id}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <Steps
                    steps={step}
                    topic={topic}
                    long={long}
                    tone={tone}
                    setTone={setTone}
                    setLong={setLong}
                    setTopic={setTopic}
                />

                <div className="action-buttons">
                    <div className="cta-btn transparent" onClick={handleLeftButtonClick}>
                        <img src={icons.leftAngleArrow} alt="left" />
                        Back
                    </div>
                    <div className="cta-btn" onClick={handleRightButtonClick}>
                        Next
                        <img src={icons.rightAngleArrowWhite} alt="right" />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
