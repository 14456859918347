import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useScreenSize, useSnackbar } from '~hooks';
import {
    AppRoutes,
    Assets,
    defaultErrorMessage,
    icons,
    PRIVACY_POLICY_URL,
    TERMS_OF_SERVICE_URL,
} from '~constants';
import { formValidation, signIn } from './Login.functions';
import type { SignInData } from './Login.types';
import { LoginForm } from '~components/LoginForm/LoginForm';
import { Header } from '~components/Header';
import { PatternBackground } from '~components/PatternBg';

import './Login.styles.scss';
import { useTranslation } from 'react-i18next';
import { RegisterSidePanel } from '~components/RegisterSidePanel';
import { ReviewCarousel } from '~components/ReviewCarousel';


const userReviews = [
    Assets.userReviewMobile1,
    Assets.userReviewMobile2,
    Assets.userReviewMobile3,
    Assets.userReviewMobile4,
];

export const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [formErrors, setFormErrors] = useState<SignInData | null>();
    const [isLoading, setIsLoading] = useState(false);

    const historyState = useLocation().state;
    const navigate = useNavigate();
    const { errorSnackbar } = useSnackbar();
    const { isMobile } = useScreenSize();

    const onInputChange = (event: any) => {
        const { name, value } = event.target;

        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        const errors = formValidation(formData);
        if (errors) {
            setFormErrors(errors);
            setIsLoading(false);
            return;
        } else {
            try {
                setFormErrors(null);
                await signIn(formData);
                navigate(AppRoutes.Dashboard);
            } catch (err: any) {
                setIsLoading(false);
                errorSnackbar(err.message || defaultErrorMessage);
            }
        }
    };

    useEffect(() => {
        if (historyState && historyState?.error) {
            errorSnackbar(historyState.error);
        }
        if (historyState && historyState?.email) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                email: historyState.email,
            }));
        }
    }, [historyState]);

    const { t } = useTranslation();

    const jj = {
        /* <Container className="login-page">
            <Header logoAlign="center" />
            <div className="login-section">
                <h1>{t('login_to')} Studybuddy+</h1>
                <h3>
                    {t('dont_have_an_account')}
                    <Link to={AppRoutes.Register}> {t('create_one')}</Link>
                </h3>
                <LoginForm
                    onChange={onInputChange}
                    onSubmit={onSubmit}
                    formData={formData}
                    formErrors={formErrors}
                    isButtonLoading={isLoading}
                />
                <p className="terms">
                    {t('by_logging_in')}{' '}
                    <Link to={TERMS_OF_SERVICE_URL}>
                        <br />
                        {t('terms_and_conditions')}{' '}
                    </Link>
                    {t('and')}{' '}
                    <Link to={PRIVACY_POLICY_URL}>{t('privacy_policy')}.</Link>
                </p>
            </div>
            <PatternBackground showImages />
        </Container> */
    };

    return (
        <div className="login-page-container">
            <Row className="login-page">
                <Col className="form-section" lg={4} md={12}>
                    {/* <Header logoAlign="left-relative" /> */}

                    <div
                        className="login-page-logo"
                        onClick={() =>
                            (window.location.href = AppRoutes.Dashboard)
                        }
                    >
                        <img src={icons.graduationBlack} alt="logo" />
                        <h3>
                            Study<span>buddy+</span>
                        </h3>
                    </div>
                    <LoginForm
                        onChange={onInputChange}
                        onSubmit={onSubmit}
                        formData={formData}
                        formErrors={formErrors}
                        isButtonLoading={isLoading}
                    />
                    <ReviewCarousel userReviews={userReviews} />
                </Col>
                <Col className="info-section" lg={8} md={0}>
                    <RegisterSidePanel />
                </Col>
                {isMobile && <div className="blur-backdrop-left"></div>}
            </Row>
        </div>
    );
};
