import React from 'react';
import DesktopFeature from '~assets/features-desktop.svg';
import MobileFeature from '~assets/features-mobile.svg';
import './NewFeature.styles.scss';

const NewFeature: React.FC = () => {
    return (
        <div id="feature-section" className="feature-section">
            <h2 className="feature-title">
                Undetectable By <span className="fe-blu">Teachers</span>
            </h2>

            <div className="feature-content">
                <div className="feature-image-wrapper">
                    <img
                        src={DesktopFeature}
                        alt="New Feature"
                        className="feature-image desktop"
                    />
                    <img
                        src={MobileFeature}
                        alt="New Feature Mobile"
                        className="feature-image-mobi mobile"
                    />
                </div>
            </div>
        </div>
    );
};

export default NewFeature;
