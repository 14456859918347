import React from 'react';
import Marquee from 'react-fast-marquee';

import { CompanyLogoItems } from './CompanyLogos.constants';

import './CompanyLogos.styles.scss';
import { useScreenSize } from '~hooks';
import { useTranslation } from 'react-i18next';

export const CompanyLogos = () => {
    const { isDesktop  } = useScreenSize();
    const { t } = useTranslation();

    return (
        <div id="companies">
            <h3>{t('beat_the_ai_detectors')}</h3>
            <div className="companies-marquee-section">
                <Marquee className="company-marquee-container" play={!isDesktop}>
                    {CompanyLogoItems.map((item, index) => (
                        <div className="company-marquee-item" key={index}>
                            <img src={item} alt="institution" />
                        </div>
                    ))}
                </Marquee>
            </div>
        </div>
    );
};
