import React from 'react';

import { useTranslation } from 'react-i18next';
import { useScreenSize } from '~hooks';

import './Numbers.styles.scss';


export const Numbers = () => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    return (
        <div id="numbers">
            <div id="numbersContainer">
                <div id="factPoint">
                    <h3>{t('83k+')}</h3>
                    <h5>{t(isMobile ? 'essays' : 'essays_written')}</h5>
                </div>
                <div id="factPoint">
                    <h3>{t('530K+')}</h3>
                    <h5>{t('students')}</h5>
                </div>
                <div id="factPoint">
                    <h3>{t('100%')}</h3>
                    <h5>{t('undetectable')}</h5>
                </div>
            </div>
        </div>
    );
};
