import React, { FC } from 'react';
import './NewHero.styles.scss';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '~hooks';
import { Assets } from '~constants';

type NewHeroProps = {
    title: string;
}

export const NewHero: FC<NewHeroProps> = (props) => {
    const { title } = props;
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    return (
        <div id="newHero">
            <div id="newHeroInner">
                <div id="newHeroLeft">
                    <h1 id="newHeroTitle">
                        Your search for an{' '}
                        <span className="ble">alternative to {title}</span>{' '}
                        ends here
                    </h1>

                    <div className="img-container">
                        <div className="mobile-img">
                            <img src={Assets.peopleGroupMobileLp} alt="Mix Icon" />
                        </div>
                        <div id="new_img_container" className="desktop-img">
                            <img src={Assets.peopleGroupLp} alt="PeopleGroupLogo" />
                            <img
                                src={Assets.trustpilotLp}
                                alt="TrustPilotLogo"
                            />
                        </div>
                    </div>

                    <a className="a" href="#cta-container ">
                        <div className="hero-cta-btn"> {t('try_now')}</div>
                    </a>
                </div>
                <div id="newHeroRight">
                    <div id="newHeroRightVideo">
                        <video
                            id="newHeroVideo"
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                            poster="/assets/images/main-gif-poster.png"
                        >
                            <source
                                src={
                                    isMobile
                                        ? 'https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/hero_main_gif_video%20(1).mp4'
                                        : 'https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/hero_main_gif_video_desktop.mp4'
                                }
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
};
