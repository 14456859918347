import Form from 'react-bootstrap/Form';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import { ReactComponent as ReWriteIcon } from '~/assets/rewrite.svg';
import { ReactComponent as ExportIcon } from '~/assets/export.svg';

import './ContentGeneratorHeader.styles.scss';

const ContentGeneratorHeader = () => {
    return (
        <div className="content-generator-header-container">
            <h1>Generated Outline</h1>
            <div className="header-controller">
                <Form.Switch type="switch" label="Combine View" />
                <div className="wordCount">{`Total Words: 309`}</div>
                <button className="humanizeButton">
                    <NinjaIcon />
                    Humanize All Text
                </button>
                <button className="rewriteButton">
                    <ReWriteIcon />
                    Rewrite{' '}
                </button>
                <button className="exportButton">
                    <ExportIcon />
                    Export{' '}
                </button>
            </div>
        </div>
    );
};

export default ContentGeneratorHeader;
