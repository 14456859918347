import React from 'react';
import './NewFooter.styles.scss';
import TiktokBlackLogo from '../../../assets/TiktokLogo_black.svg';
import StudybuddyLogoBlack from '../../../assets/studybuddy-logo-black.svg';
import InstaBlackLogo from '../../../assets/InstaLogo_black.svg';
import XBlackLogo from '../../../assets/XLogo_black.svg';
import { useTranslation } from 'react-i18next';

export const NewFooter = () => {
    const { t } = useTranslation();

    return (
        <footer id="newFooter" className="newFooter">
            <div className="newFooterContainer">
                <div className="newFooterTop">
                    <div className="newFooterBrand">
                        <a href="/">
                            <img
                                src={StudybuddyLogoBlack}
                                alt="logo"
                                className="newFooterLogo"
                            />
                        </a>
                        <div className="newSocialsBlock">
                            <a
                                href="https://www.tiktok.com/@getstudybuddy.gg"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={TiktokBlackLogo} alt="tiktok" />
                            </a>
                            <a
                                href="https://www.instagram.com/studybuddy.gg"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={InstaBlackLogo} alt="instagram" />
                            </a>
                            <a
                                href="https://twitter.com/studybuddy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={XBlackLogo} alt="x" />
                            </a>
                        </div>
                    </div>
                    <div className="newFooterLinks">
                        <div>
                            <div className="newFooterListTitle">
                                {t('product')}
                            </div>
                            <ul className="newFooterList">
                                <li className="newFooterListItem">
                                    <a
                                        href="#pricing-section"
                                        className="newFooterLink"
                                    >
                                        {t('pricing')}
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="#feature-containers"
                                        className="newFooterLink"
                                    >
                                        {t('features')}
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="https://chrome.google.com/webstore/detail/studybuddy%2B/ehaanimekcjndnhnkojcckjcgalkfgee/"
                                        className="newFooterLink"
                                    >
                                        {t('chrome_extension')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="newFooterListTitle">
                                {t('company')}
                            </div>
                            <ul className="newFooterList">
                                <li className="newFooterListItem">
                                    <a
                                        href="#newUserReview"
                                        className="newFooterLink"
                                    >
                                        {t('reviews')}
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="/privacy-policy"
                                        className="newFooterLink"
                                    >
                                        {t('privacy_policy')}
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="/terms-conditions"
                                        className="newFooterLink"
                                    >
                                        {t('terms_of_service')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="newFooterListTitle">
                                {t('help')}
                            </div>
                            <ul className="newFooterList">
                                <li className="newFooterListItem">
                                    <a
                                        href="mailto:support@studybuddy.gg"
                                        className="newFooterLink"
                                    >
                                        {t('contact_us')}
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a href="#faq" className="newFooterLink">
                                        FAQ's
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="newFooterListTitle">
                                {t('compare')}
                            </div>
                            <ul className="newFooterList">
                                <li className="newFooterListItem">
                                    <a
                                        href="http://studybuddy.gg/alternative/coursology"
                                        className="newFooterLink"
                                    >
                                        Coursology alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a href="http://studybuddy.gg/alternative/photomath" className="newFooterLink">
                                        Photomath alternative
                                    </a>
                                    <a
                                        href="http://studybuddy.gg/alternative/gauthmath"
                                        className="newFooterLink"
                                    >
                                        Gauthmath alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a href="http://studybuddy.gg/alternative/photosolve" className="newFooterLink">
                                        Photosolve alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a href="http://studybuddy.gg/alternative/collegetools" className="newFooterLink">
                                        Collegetools alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a
                                        href="http://studybuddy.gg/alternative/transcript"
                                        className="newFooterLink"
                                    >
                                        Transcript alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a href="http://studybuddy.gg/alternative/smartsolve" className="newFooterLink">
                                        Smartsolve alternative
                                    </a>
                                    <a
                                        href="http://studybuddy.gg/alternative/homeworkify"
                                        className="newFooterLink"
                                    >
                                        Homeworkify alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a href="http://studybuddy.gg/alternative/answersAi" className="newFooterLink">
                                        AnswersAi alternative
                                    </a>
                                </li>
                                <li className="newFooterListItem">
                                    <a href="http://studybuddy.gg/alternative/studyxai" className="newFooterLink">
                                        Studyx.ai alternative
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="newFooterBottomContainer">
                    <hr className="newFooterHr" />
                    <small className="newCopyright">© Studybuddy 2024</small>
                </div>
            </div>
        </footer>
    );
};
