import React from 'react';

import './DocumentCard.styles.scss';
const DocumentCard = () => {
    return (
        <div className="card-container">
            <div className="card-content">
                <p>
                    George Washington, often referred to as the "Father of His
                    Country," played a pivotal role in shaping the United States
                    of America. His contributions as a military leader,
                    statesman, and first president have left an indelible mark
                    on the nation's history. Washington's life and legacy
                    exemplify leadership, resilience, and an unwavering
                    commitment to the ideals of liberty and democracy.
                </p>
            </div>
            <div className="card-title">
                <h3>George Washington: The Father of the United States</h3>
                <span> Last edited 08/22/2024</span>
            </div>
        </div>
    );
};

export default DocumentCard;
