import React, { FC, useState, useEffect } from 'react';
import './Steps.styles.scss';
import { toneOfVoice } from '../WriterSteps.constants';
import Slider from '~components/Slider/Slider';
import { postWithAuth } from '~utils/apiUtil';
import { useAuth, useScreenSize } from '~hooks';
import { TITLE_GENERATOR_URL } from '~constants/urls';

type StepsProps = {
    steps: number;
    topic?: string;
    long?: number;
    tone?: string;
    setTone: (value: string) => void;
    setLong: (value: number) => void;
    setTopic: (value: string) => void;
};

export const Steps: FC<StepsProps> = (props) => {
    const { steps, topic, setTopic, long, setLong, tone, setTone } = props;

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedTone, setSelectedTone] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>(null); // State to store the title
    
    const { token } = useAuth();

    const sliderItems = [
        { label: 'Short', value: 1 },
        { label: 'Medium', value: 3 },
        { label: 'Long', value: 9 },
        { label: 'Very long', value: 20 },
    ];

    const handleSliderChange = (index: number) => {
        setSelectedIndex(index);
        const selectedValue = sliderItems[index].value;
        setLong(selectedValue);
    };

    const handleToneClick = (toneName: string) => {
        setSelectedTone(toneName);
        setTone(toneName);
    };

    const getTitleHeaders = async (): Promise<void> => {
        try {
            const generateTitle = await postWithAuth(
                TITLE_GENERATOR_URL,
                { topic: topic },
                token as string
            );
            const titleContent = generateTitle?.data?.data?.choices[0].message.content;
            setTitle(titleContent); // Set the title state with the response
        } catch (error) {
            console.error('Error fetching title:', error);
        }
    };

    useEffect(() => {
        if (steps === 4) {
            getTitleHeaders(); // Fetch title when steps === 4
        }
    }, [steps]); // Only call this when `steps` changes

    return (
        <div className="steps-container">
            {steps === 1 && (
                <div className="first-step-content">
                    <textarea
                        value={topic}
                        placeholder="Type your topic here"
                        onChange={(e) => setTopic(e.target.value)}
                    />
                    <p>
                        <strong>Weak Prompt:</strong> Try adding more details for stronger, higher-quality outputs
                    </p>
                </div>
            )}
            {steps === 2 && (
                <div className="second-step-content">
                    <div className="slider-container">
                        <Slider items={sliderItems} selectedIndex={selectedIndex} setSelectedIndex={handleSliderChange} />
                    </div>
                    <p>Approx. {sliderItems[selectedIndex].value} words</p>
                </div>
            )}
            {steps === 3 && (
                <div className="third-step-content">
                    <div className="tone-of-voice-container">
                        {toneOfVoice.map((tone) => (
                            <div
                                key={tone.id}
                                className={`tone-of-voice ${selectedTone === tone.name ? 'active' : ''}`}
                                onClick={() => handleToneClick(tone.name)}
                            >
                                <p>{tone.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {steps === 4 && (
                <div className="fourth-step-content">
                    {title ? (
                        <ol>
                            {title.split('\n').map((line, index) => (
                                <li className="title-item">
                                    <strong>{line}</strong>
                                </li>
                            ))}
                        </ol>
                    ) : (
                        <p>Loading titles...</p>
                    )}
                </div>
            )}
        </div>
    );
};
