export const API_NAME = 'studybuddyapi';
export const API_NAME_STRIPE_AWS = 'studybuddystripewebhooks';
export const API_NAME_PAYPAL_AWS = 'studybuddyPaypal';

export const USER_DETAILS_API = '/userdetails';
export const USER_NAME_UPDATE_API = '/updateName';
export const USER_EMAIL_CHECK_API = '/checkEmail';
export const CREATE_PAYMENT_INTENT_API = '/createPaymentIntent';
export const GET_CARD_DETAILS_API = '/getCardDetails';
export const GET_STRIPE_USER_DETAILS_API = '/getStripeUserDetails';
export const GET_CANCELLATION_HASH = '/cancelSubscription';
export const PAUSE_SUBSCRIPTION_UNTIL_SEPTEMBER_API = '/pauseUntilSeptember';
export const RESUBSCRIBE_SUBSCRIPTION_API = '/stripeResubscribe';
export const VIRAL_SHARE_CREDIT_API = '/viralShareCredit';
export const LIST_INVOICES_API = '/listInvoices';
export const POST_PAYMENT_API = '/postPayment';
export const CREATE_PAYPAL_SUBSCRIPTION_API = '/paypalCreateSubscription';
export const SEMESTER_PLAN_UPGRADE_API = '/stripeSemesterPlanUpgrade';
export const TEST_FUNCTION_API = '/testrun';
export const STUDYBUDDY_AI_CHAT_API = '/aiChat';

export const EXTENSION_ID = 'ehaanimekcjndnhnkojcckjcgalkfgee';
export const CHROME_STORE_EXTENSION_URL =
    'https://chrome.google.com/webstore/detail/studybuddy/ehaanimekcjndnhnkojcckjcgalkfgee';
export const CHROME_EXTENSION_DOWNLOAD_URL = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/studybuddy-extension.zip';
export const STUDYBUDDY_HOME_URL = 'https://www.studybuddy.gg/home';
export const STUDYBUDDY_PRICING_URL = 'https://studybuddy.gg/#priceTitle';
export const PRIVACY_POLICY_URL = 'https://www.studybuddy.gg/privacy-policy';
export const TERMS_OF_SERVICE_URL = 'https://www.studybuddy.gg/terms-conditions';
export const HELP_CENTER_URL = 'https://help.studybuddy.gg';

export const CK_ID = '4m6e3ebcb';