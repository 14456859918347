import React from 'react';
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import SemesterUpsellDesktop from '~/assets/onboarding-semester-upsell-desktop.svg';
import SemesterUpsellMobile from '~/assets/onboarding-semester-upsell-mobile.svg';
import { useTranslation } from 'react-i18next';
import { semesterPlanUpgrade } from '~components/PaymentPlan';
import { emitSemeseterPlanEvent } from '~utils/advertisingEvents';
import { logger } from '~utils';

const SemesterDealUpsell = (props: any) => {
    const { onRightButtonClick, increaseStep } = props;
    const { isMobile, isTablet } = useScreenSize();
    const { t } = useTranslation();
    const { user, token, planStatus } = useAuth();
    const { infoSnackbar, successSnackbar, errorSnackbar } = useSnackbar();

    // TODO: Add semester upgrade plan logic, if upgraded to semester, finish onboarding and return to dashboard
    const customerId = user?.data?.attributes['custom:stripeCustomerID'];
    const onUpgradeBtnClick = async () => {
        // setLoading(true);
        infoSnackbar(`${t('upgrading_sub')}...`);
        const res = await semesterPlanUpgrade(token, customerId);
        logger.info('semester subscription result >> ' + res);
        if (res === 'Success') {
            await emitSemeseterPlanEvent();
            successSnackbar('Semester subscription started successfully');
            window.location.reload();
        } else {
            errorSnackbar(
                'Something went wrong, try again. This is likely because your bank declined the charge, please try again with a different card.',
            );
            // setLoading(false);
        }
    };
    const handleUpgradeSemester = () => {
        onUpgradeBtnClick();
        onRightButtonClick();
    };

    return (
        <div>
            <img
                style={{ objectFit: 'cover' }}
                src={isMobile ? SemesterUpsellMobile : SemesterUpsellDesktop}
                alt="semester-upsell"
            />
            <button onClick={handleUpgradeSemester} className="upsell-btn">
                {isMobile ? 'Upgrade & Save' : 'Upgrade & Save $32'}
            </button>
            <button onClick={increaseStep} className="upsell-cancel-btn">
                {'I prefer to pay more money'}
            </button>
        </div>
    );
};

export default SemesterDealUpsell;
