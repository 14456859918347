// NewFeature.tsx
import React from 'react';
import DesktopFeature from '~assets/newfeatures.png';
import MobileFeature from '~assets/new_features_mobie.png';
import './NewFeature.styles.scss'; // Import the SCSS file for styling
import { t } from 'i18next';

const NewFeature: React.FC = () => {
    return (
        <div id="feature-containers">
            <div className="feature-container">
                <div className="feature-right">
                    <div className="newFeature">
                        <img
                            src={DesktopFeature}
                            alt="New Feature"
                            className="desktopFeature"
                        />
                        <img
                            src={MobileFeature}
                            alt="New Feature Mobile"
                            className="mobileFeature"
                        />
                    </div>
                </div>
            </div>

            <div className="feature-right">
                <h2>
                    Nothing comes close to{' '}
                    <span className="blu">Studybuddy</span>
                </h2>
                <a className="au" href="#cta-container">
                <div className="feature-cta-btn"> {t('try_now')}</div>
                </a>
            </div>
        </div>
    );
};

export default NewFeature;
