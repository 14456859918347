import React from 'react';
import './IconWithText.styles.scss';
import greenTick from '~/assets/green-tick.svg';
import redCrossIcon from '~/assets/red-cross.svg';
import warningIcon from '~/assets/warning.svg';

export type Status = 'DETECTED' | 'UNDETECTED' | 'WARNING' | 'NONE';

interface IconWithTextProps {
    text: string;
    isBold?: boolean;
    status: Status;
}

const getIcon = (status?: Status) => {
    switch (status) {
        case 'UNDETECTED':
            return greenTick;
        case 'DETECTED':
            return redCrossIcon;
        case 'WARNING':
            return warningIcon;
        case 'NONE':
            return;
    }
    return '';
};

const IconWithText = ({
    text,
    isBold = false,
    status,
}: Readonly<IconWithTextProps>) => {
    const shouldShowIcon = status !== 'NONE';

    return (
        <span className={`icon-with-text ${isBold ? 'text-font-bold' : ''}`}>
            {shouldShowIcon ? (
                <img
                    src={getIcon(status)}
                    height="16px"
                    width="16px"
                    alt="icon"
                />
            ) : null}
            {text}
        </span>
    );
};

export default IconWithText;
