export type WriterStepsType = {
    id: number;
    stepName: string;
};

export const writerSteps: WriterStepsType[] = [
    {
        id: 1,
        stepName: '👋 What do you want us to write about?',
    },
    {
        id: 2,
        stepName: '🤔 How long should it be?',
    },
    {
        id: 3,
        stepName: '🧐 How should it sound? Pick your tone of voice',
    },
    {
        id: 4,
        stepName: 'Review & edit your table of contents',
    }
];

export const toneOfVoice = [
    {
        id: 1,
        name: 'Analytical',
    },
    {
        id: 2,
        name: 'Descriptive',
    },
    {
        id: 3,
        name: 'Formal',
    },
    {
        id: 4,
        name: 'Persuasive',
    },
    {
        id: 5,
        name: 'Narrative',
    },
    {
        id: 6,
        name: 'Informative',
    },
    {
        id: 7,
        name: 'Casual',
    },
    {
        id: 8,
        name: 'Objective',
    }
]
