import React from 'react';
import './ToolItem.styles.scss';

interface ToolsItemProps {
    icon: string;
    title: string;
    buttonText: string;
    action: () => void;
    children: React.ReactNode;
    buttonIcon?: string;
}

const ToolItem: React.FC<ToolsItemProps> = ({
    icon,
    title,
    buttonText,
    action,
    children,
    buttonIcon,
}) => {
    return (
        <div className="toolItemContainer">
            <div className="toolItemHeader">
                <img src={icon} alt="imagenotfound" />
                <span>{title}</span>
            </div>
            <div className="toolItemDescription">{children} </div>
            <div className="toolItemFooter">
                <button
                    onClick={action}
                    className="tooItemButton"
                >
                    {buttonIcon && (
                        <img
                            src={buttonIcon}
                            alt="imagenotFound"
                            height="16px"
                            width="16px"
                        />
                    )}
                    {buttonText}
                </button>
            </div>
        </div>
    );
};
export default ToolItem;
