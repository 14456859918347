import { ContentTextArea } from '~components/ContentTextArea/ContentTextArea';

import { useMemo, useState } from 'react';
import './AiDetectorContent.styles.scss';
import IconWithText from '~/components/IconWithText/IconWithText';
import AiLikeLiHoodPercentage from './AiLikeliHoodPercentage/AiLikeliHoodPercentage';
import { AiDetectorHeader } from './AiDetectorHeader/AIDetectorHeader';
import AiDetectorFooter from './AiDetectorFooter/AiDetectorFooter';

export const WriterAIDetector = () => {
    const [text, setText] = useState('');
    const [isInDetectMode, setIsInDetectMode] = useState(false);
    const [isDected, setIsDetected] = useState(false);

    const toggleIsDetected = () => {
        setIsDetected(!isDected);
    };

    const status = useMemo(() => {
        if (isInDetectMode && isDected) {
            return 'DETECTED';
        }
        if (isInDetectMode && !isDected) {
            return 'UNDETECTED';
        }

        return 'NONE';
    }, [isDected, isInDetectMode]);

    const percentage = useMemo(() => {
        if (isInDetectMode && isDected) {
            return 90;
        }
        if (isInDetectMode && !isDected) {
            return 0;
        }

        return undefined;
    }, [isDected, isInDetectMode]);

    return (
        <>
            <header>
                <h1 className="dashboard-title">AI Detector</h1>
            </header>
            <div className="ai-detector-container">
                <AiDetectorHeader
                    text={text}
                    isInDetectMode={isInDetectMode}
                    setIsInDetectMode={setIsInDetectMode}
                    toggleIsDetected={toggleIsDetected}
                />
                <div className="ai-detector-body">
                    <ContentTextArea
                        isInDetectMode={isInDetectMode}
                        onTextChange={setText}
                        gapClass="gap-24"
                        isHighlighted={isDected}
                    />
                    <div></div>
                </div>
                <AiDetectorFooter status={status} percentage={percentage} />
            </div>
        </>
    );
};
