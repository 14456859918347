import React, { FC } from 'react';

import CheckIcon from '~assets/check-icon.svg';
// import HeroVideo from '../../../assets/videos/video1.mp4';
/* import HeroGifVideo from '~assets/videos/hero_main_gif_video.mp4'; */
import { useTranslation } from 'react-i18next';
import { useScreenSize, useInView } from '~hooks';
import { Assets } from '~constants';

import './Hero.styles.scss';

interface HeroProps {
    setIsPricingInView?: React.Dispatch<React.SetStateAction<boolean>>;
    scrollToPricing: () => void;
}

export const Hero: FC<HeroProps> = ({
    setIsPricingInView,
    scrollToPricing,
}) => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();
    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    React.useEffect(() => {
        setIsPricingInView?.(inView);
    }, [inView, setIsPricingInView]);

    return (
        <div id="hero">
            {/* <img
                id="heroRightGradientMobile"
                src={HeroRightGradientMobile}
                alt="hero_right_gradient_mobile"
            /> */}
            <div id="heroInner">
                <div id="heroLeft">
                    {isMobile ? (
                        <div id="heroTrustContainerMobile">
                            <img src={Assets.trustPilotWriterMobile} alt="HeroRatingLogo" />
                        </div>
                    ) : (
                        <div id="heroTrustContainer">
                            <img className='people' src={Assets.peopleGroupLp} alt="PeopleGroupLogo" />
                            <img
                                src={Assets.trustpilotLp}
                                alt="trustPilotLogo"
                            />
                        </div>
                    )}

                    <h1 id="heroTitle">
                        {/* {t('instant_answers')} */}
                        AI {t('chrome_extension')}
                        {/* <br /> <span>{t('hero_title_2')}</span> */}
                        <br />{' '}
                    </h1>
                    <h2 id="heroSubTitle">{t('helps_test_quiz_homeworks')}</h2>
                    {/* <p className="hero-description">{t('hero_desc')}</p> */}

                    {/* desktop version */}
                    <ul id="heroFeatures">
                        <div className="feature-list-container">
                            <img src={CheckIcon} alt="check-icon" />
                            <li className="heroFeature">
                                <p style={{ width: '100%' }}>
                                    {t('works_any_online')}{' '}
                                    <span>{t('assignment/platform')}</span>
                                </p>
                            </li>
                        </div>

                        <div className="feature-list-container">
                            <img src={CheckIcon} alt="check-icon" />
                            <li className="heroFeature">
                                {/* {t('incognito_stealth')} */}
                                {t('over')} <span>{t('530,000+')}</span>{' '}
                                {t('active_users')}
                            </li>
                        </div>

                        <div className="feature-list-container">
                            <img src={CheckIcon} alt="check-icon" />
                            <li className="heroFeature">
                                {t('fully')}{' '}
                                <span>
                                    {t('undetectable')} {t('&_plagiarism')}
                                </span>{' '}
                                <p
                                    style={{
                                        textTransform: 'lowercase',
                                        display: 'inline',
                                    }}
                                >
                                    {t('free')}
                                </p>
                            </li>
                        </div>

                        <div className="feature-list-container">
                            <img src={CheckIcon} alt="check-icon" />
                            <li className="heroFeature">
                                {/* <span>100%</span> {t('foolproof_undetectable')} */}
                                <span>{t('instant_answers')}</span>{' '}
                                {t('&_explanations')}
                            </li>
                        </div>
                    </ul>
                    <a onClick={scrollToPricing}>
                        <div
                            className="hero-cta-btn"
                            ref={ref as React.LegacyRef<HTMLDivElement>}
                        >
                            {' '}
                            {t('try_now')}
                        </div>
                    </a>
                </div>
                <div id="heroRight">
                    <div id="heroRightVideo">
                        <video
                            id="heroVideo"
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                            poster="/assets/images/main-gif-poster.png"
                        >
                            <source
                                src={
                                    isMobile
                                        ? 'https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/hero_main_gif_video%20(1).mp4'
                                        : 'https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/hero_main_gif_video_desktop.mp4'
                                }
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    {/* <p className="HideOnMobile" id="heroTagline">
                        {' '}
                        AI {t('chrome_extension')}:{' '}
                    </p> */}

                    {/* mobile version */}
                    <ul id="heroFeaturesMobile">
                        <li className="heroFeatureMobile noMarginTop">
                            {t('works_any_online')}{' '}
                            <span>{t('assignment/platform')}</span>{' '}
                        </li>
                        <li className="heroFeatureMobile">
                            {t('over')} <span>{t('530,000+')}</span>{' '}
                            {t('active_users')}
                        </li>

                        <li className="heroFeatureMobile">
                            {t('fully')}{' '}
                            <span style={{ textTransform: 'lowercase' }}>
                                {t('undetectable')}
                            </span>{' '}
                            {t('&_plagiarism_free')}
                        </li>
                        <li className="heroFeatureMobile">
                            <span>{t('instant_answers')}</span>{' '}
                            {t('&_explanations')}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
