import React from 'react';
import Form from 'react-bootstrap/Form';
import './Slider.scss';

interface SliderProps {
    items: { label: string; value: number }[];
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
}

const Slider: React.FC<SliderProps> = ({ items, selectedIndex, setSelectedIndex }) => {
    const handelRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedIndex(parseInt(e.target.value));
    };

    return (
        <div className="slider">
            <Form.Range
                min={0}
                max={items.length - 1}
                step={1}
                value={selectedIndex}
                onChange={handelRangeChange}
            />
            <div className="ranges-container">
                {items.map((item, index) => (
                    <div key={index} className="range-item">
                        <p>{item.label}</p>
                        <p>{`(${item.value} pages)`}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Slider;
