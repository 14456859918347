import React, { useState } from 'react';
import ToolItem from './ToolItem/ToolItem';
import pencilCircledIcon from '~/assets/pencil-circled.svg';
import ninjaCicledIcon from '~/assets/ninja-circled.svg';
import scanCircledIcon from '~/assets/scan-circled.svg';
import plusIcon from '~/assets/plus.svg';
import { WriterSteps } from '../WriterSteps';

import './WriterTools.styles.scss';

const WriterTools = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleCTA = () => {
        console.log('handleCTA called >>>');
    };
    const handleButtonClick = () => {
        console.log('Thanks for clicking me');
    };
    return (
        <>
            <div className="tools-container">
                <p className="tools-text">Tools</p>
                <div className="dashboard-tool-items">
                    <ToolItem
                        icon={pencilCircledIcon}
                        title="Essay Writter"
                        buttonText="Start Writting"
                        action={() => setShow(true)}
                        // buttonIcon={plusIcon}
                    >
                        Write <strong>A+ essays</strong> in seconds with just
                        one click and produce high-quality writing quickly and
                        effortlessly.
                    </ToolItem>
                    <ToolItem
                        icon={ninjaCicledIcon}
                        title="Humanize AI Text"
                        buttonText="Upload your Text"
                        action={handleButtonClick}
                        buttonIcon={plusIcon}
                    >
                        <strong>AI Humanizer</strong> makes sure your text won't
                        be flagged by tools like GPT Zero or Turnitin, keeping
                        it original.
                    </ToolItem>
                    <ToolItem
                        icon={scanCircledIcon}
                        title="AI Detector"
                        buttonText="Check your Text"
                        action={handleButtonClick}
                        // buttonIcon={plusIcon}
                    >
                        Verify if your AI-generated content can be identified by
                        leading <strong>AI detection platforms.</strong>
                    </ToolItem>
                </div>
            </div>
            <WriterSteps
                show={show}
                handleClose={handleClose}
                handleCTA={handleCTA}
            />
        </>
    );
};

export default WriterTools;
