import React from 'react';
import { Assets } from '~constants';

import './NewReviews.styles.scss'; // Import the SCSS file for styling

const NewReviews: React.FC = () => {
    return (
        <div id="testimonials-section">
        <div id="reviews-section" className="reviews-section">
            <div className="reviews-content">
                <div className="reviews-image-wrapper">
                    <img
                        src={Assets.reviewsWriterTool}
                        alt="User Reviews"
                        className="reviews-image desktop"
                    />
                    <img
                        src={Assets.reviewsWriterToolMobile}
                        alt="User Reviews Mobile"
                        className="reviews-image mobile"
                    />
                </div>
            </div>
        </div>
        </div>
    );
};

export default NewReviews;
