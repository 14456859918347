import React from 'react';

interface TextAreaActionProps {
    icon: string;
    label: string;
    action: any;
}

const TextAreaAction: React.FC<TextAreaActionProps> = ({
    icon,
    label,
    action,
}) => {
    return (
        <div className="action" onClick={action}>
            <img src={icon} alt="Icon" height="16px" width="16px" />
            {label}
        </div>
    );
};

export default TextAreaAction;
