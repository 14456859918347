import React, { useEffect, useRef } from 'react';

interface ContentTextAreaInputProps {
    text: string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    disable?: boolean;
    handleKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

const ContentTextAreaInput = React.forwardRef<
    HTMLTextAreaElement,
    React.PropsWithChildren<ContentTextAreaInputProps>
>(({ text, onChange, disable, handleKeyDown }, ref) => (
    <textarea
        ref={ref}
        className="textContentTextAreaInput"
        onChange={onChange}
        disabled={disable}
        onKeyDown={handleKeyDown}
        value={text}
    ></textarea>
));

export default ContentTextAreaInput;
