import React, { FC } from 'react';
import { Assets } from '~constants';
import { institutions } from '~pages/Register/Register.constants';
import { useTranslation } from 'react-i18next';
import './RegisterSidePanel.styles.scss';

type RegisterSidePanelProps = {
    isCheckoutPage?: boolean;
    isInPopup?: boolean; // New prop to handle popup rendering
};

export const RegisterSidePanel: FC<RegisterSidePanelProps> = (props) => {
    const { isCheckoutPage, isInPopup } = props;
    const { t } = useTranslation();

    return (
        <div className={`register-side-panel ${isInPopup ? 'popup-version' : ''}`}>
            <div className="contain">
                <h1 className="student-title">
                    <span>530K+</span> Students
                </h1>
                <div  className={`user-review-container ${isInPopup ? 'popup-version-img' : ''}`}>
                    <img src={Assets.UserReview} alt="register side panel" className={`${isInPopup ? 'popup-version-img' : ''}`} />
                    <div className="profile-name"></div>
                </div>
            </div>

            <div className="Marquee">
                <div className="MarqueeGroup">
                    {institutions.map((institution, index) => (
                        <div className="MarqueeItem" key={index}>
                            <img src={institution} alt="institution" />
                        </div>
                    ))}
                </div>
            </div>
            <div className="blur-backdrop-left"></div>
        </div>
    );
};
