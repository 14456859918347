import React from 'react';
import { useTranslation } from 'react-i18next';
import './NewNumbers.styles.scss';

export const NewNumbers = () => {
    const { t } = useTranslation();

    return (
        <div className="new-numbers">
            <div className="new-numbers-container">
                <div className="new-fact-point">
                    <h3>{t('10M+')}</h3>
                    <h5>{t('solutions')}</h5>
                </div>
                <div className="new-fact-point">
                    <h3>{t('530K+')}</h3>
                    <h5>{t('students')}</h5>
                </div>
                <div className="new-fact-point">
                    <h3>{t('98%')}</h3>
                    <h5>{t('accurate')}</h5>
                </div>
            </div>
        </div>
    );
};
