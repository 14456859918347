import React from 'react';
import { Assets } from '~constants';

import './NewFeature.styles.scss';

const NewFeature: React.FC = () => {
    return (
        <div id="feature-section" className="feature-section">
            <h2 className="feature-title">Undetectable By Teachers</h2>

            <div className="feature-content">
                <div className="feature-image-wrapper">
                    <img
                        src={Assets.featuresWritersPage}
                        alt="New Feature"
                        className="feature-image desktop"
                    />
                    <img
                        src={Assets.featuresWritersPageMobile}
                        alt="New Feature"
                        className="feature-image-mobi mobile"
                    />
                    {/* <MobileFeature className="feature-image-mobi mobile" /> */}
                </div>
            </div>
        </div>
    );
};

export default NewFeature;
