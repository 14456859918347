import { getClassNameByNumber } from '~utils/getClassByNumber';
import './AIlikeliHood.styles.scss';

interface AiLikeLiHoodPercentageProps {
    num?: number;
}
const AiLikeLiHoodPercentage: React.FC<AiLikeLiHoodPercentageProps> = ({
    num,
}) => {
    const percentage = typeof num === 'number' ? `${num}%` : '%';

    return (
        <>
            <div className={`ai-generated-text ${getClassNameByNumber(num)}`}>
                {percentage}
            </div>
            <div>of text is likely AI-generated</div>
        </>
    );
};

export default AiLikeLiHoodPercentage;
