import React from 'react';
import DesktopAiImage from '~assets/ai.svg';
import MobileAiImage from '~assets/ai_mobi.svg';
import './AiFeature.styles.scss'; // Import the SCSS file for styling
import { t } from 'i18next';

const AiFeature: React.FC = () => {
    return (
        <div id="ai-feature-container">
            <div className="ai-text">
                <h2>
                    Studybuddy uses the <br />{' '}
                    <span className="blu">latest generation of AI</span>
                </h2>
                <p>
                    Studybuddy is powered by a rich library of over <span className='bluo'>500 million </span>
                    academic articles
                </p>
            </div>
            <div className="ai-image">
                <img
                    src={DesktopAiImage}
                    alt="AI Feature Desktop"
                    className="desktopAiImage"
                />
                <img
                    src={MobileAiImage}
                    alt="AI Feature Mobile"
                    className="mobileAiImage"
                />
            </div>
        </div>
    );
};

export default AiFeature;
