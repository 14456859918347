import React, { useState, useRef, useEffect } from 'react';

interface ContentEditableDivProps {
    text: string;
    isHighlighted?:boolean;
}


const ContentEditableDiv: React.FC<ContentEditableDivProps> = ({ text,isHighlighted }) => {
    const contentEditableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentEditableRef.current) {
            contentEditableRef.current.innerHTML = `<mark>${text.replace(/\n/g, '<br>')}</mark>`;
        }
    }, []);

    return (
        <div
            ref={contentEditableRef}
            contentEditable={true}
            className={
                `contentEditableDiv ${isHighlighted && 'highlight'}`
            }
        />
    );
};

export default ContentEditableDiv;
