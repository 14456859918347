import React, { useState } from 'react';
import { WriterSteps } from './WriterSteps';

import wavingHand from '~/assets/waving-hand.svg';
import './WriterDashboard.styles.scss';
import WriterTools from './WriterTools/WriterTools';

export const WriterDashboard = () => {
    return (
        <div>
            <header>
                <h1 className="dashboard-title d-flex">
                    <img src={wavingHand} />
                    Welcome back to StudyBuddy!
                </h1>
            </header>
            <div className="dashboard-container">
                <WriterTools />
            </div>
        </div>
    );
};
