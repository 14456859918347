import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { SemesterDealPopupProps } from './SemesterDealPopup.types';

import { useTranslation } from 'react-i18next';
import SemesterDealPopupDesktop from '~assets/semesterdeal-popup-desktop.svg';
import SemesterDealPopupMobile from '~assets/semesterdeal-popup-mobile.svg';

import './SemesterDealPopup.styles.scss';
import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import { CountDown } from '~components/CountDown';
import { semesterPlanUpgrade } from '~components/PaymentPlan';
import { logger } from '~utils';
import { emitSemeseterPlanEvent } from '~utils/advertisingEvents';

export const SemesterDealPopup: FC<SemesterDealPopupProps> = (props) => {
    const { show, handleClose } = props;
    const { user, token, planStatus } = useAuth();
    const { infoSnackbar, successSnackbar, errorSnackbar } = useSnackbar();

    const customerId = user?.data?.attributes['custom:stripeCustomerID'];
    const onUpgradeBtnClick = async () => {
        // setLoading(true);
        infoSnackbar(`${t('upgrading_sub')}...`);
        const res = await semesterPlanUpgrade(token, customerId);
        logger.info('semester subscription result >> ' + res);
        if (res === 'Success') {
            await emitSemeseterPlanEvent();
            successSnackbar('Semester subscription started successfully');
            handleClose();
            window.location.reload();
        } else {
            errorSnackbar(
                'Something went wrong, try again. This is likely because your bank declined the charge, please try again with a different card.',
            );
            // setLoading(false);
        }
    };

    const { isMobile } = useScreenSize();

    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            contentClassName="modalContent"
        >
            <div className="semesterDealContent">
                <img
                    src={
                        isMobile
                            ? SemesterDealPopupMobile
                            : SemesterDealPopupDesktop
                    }
                    alt="try_now"
                />
                <p className="content-text">
                    {'88% of people took this offer'}
                </p>
                <p className="content-text-sm">
                    {'But hurry, this offer ends in: '}
                </p>
                <CountDown limit={600} />
                <div className="semesterDeal-body">
                    <button
                        className="popup-cta-btn"
                        onClick={onUpgradeBtnClick}
                    >
                        {isMobile ? 'I want to save $32' : 'Upgrade & Save'}
                    </button>
                    <button onClick={handleClose} className="popup-noThanks">
                        {"No, I'll pay full price"}
                    </button>
                </div>
            </div>
        </Modal>
    );
};
