import WriterTools from '../WriterTools/WriterTools';
import DocumentCard from './DocumentCard/DocumentCard';
import './WriterDocument.styles.scss';
export const WriterDocument = () => {
    return (
        <>
            <header>
                <h1 className="dashboard-title d-flex">
                    Welcome back to StudyBuddy!
                </h1>
                <p className="document-sub-title">
                    Here is an in-depth view of the documents that you have
                    humanized and generated.
                </p>
            </header>
            <div className="document-container">
                <WriterTools />
                <h2 className="documents-title">Documents</h2>
                <div className="documents-cards-container">
                    {Array(8)
                        .fill(0)
                        .map((_, index) => {
                            return <DocumentCard key={index} />;
                        })}
                </div>
            </div>
        </>
    );
};
