// NewUserReview.tsx
import React from 'react';
import './NewUserReview.styles.scss'; // Import the SCSS file for styling
import { Assets } from '~constants';

const NewUserReview: React.FC = () => {
    return (
        <div id="newUserReview">
            <img
                src={Assets.trustPilotWriterMobile}
                alt="User Review"
                className="desktopUserReview"
            />
            <img
                src={Assets.trustPilotWriterMobile}
                alt="User Review Mobile"
                className="mobileUserReview"
            />
        </div>
    );
};

export default NewUserReview;
