import React from 'react';
import copyText from '~/assets/copy-text.svg';

export default function HumanzedResultContent({ text }: any) {
    const copyTextToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(text)
                .then(() => console.log('Text copied to clipboard'))
                .catch((err) => console.error('Failed to copy text: ', err));
            alert('text copied !');
        } else {
            console.warn('Clipboard API not supported');
        }
    };
    return (
        <div className="humanizeResultText">
            <button className="wordCount"> {text? text?.trim().split(/\s+/).filter(Boolean)?.length:0}  words</button>
            <div className="copyText" onClick={copyTextToClipboard}>
                <img src={copyText} alt="copytext" width="16px" height="16px" />
                Copy
            </div>
        </div>
    );
}
