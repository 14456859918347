import { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '~hooks'; // Import the useAuth hook

import {
    AppRoutes,
    icons,
    PRIVACY_POLICY_URL,
    TERMS_OF_SERVICE_URL,
} from '~constants';
// import { inputFieldStyles } from './constants';

import './MobileOnboard.styles.scss';
// import mr1 from './mr-1.svg';
// import mr2 from './mr-2.svg';
// import eyeIcon from './eye.svg';
// import ORImg from '~assets/OR-img.svg';
// import DownloadAppImg from '~assets/download-app-prompt.svg';

export const MobileOnboard = () => {
    const [email, setEmail] = useState('');
    const { t } = useTranslation();
    const { user, checkAuthState } = useAuth(); // Use the useAuth hook
    const navigate = useNavigate();

    useEffect(() => {
        // Check the authentication state and set the email if available
        const fetchUserData = async () => {
            const authState = await checkAuthState();
            if (authState?.data) {
                setEmail(authState.data.attributes.email); // Set email from user data
            }
        };

        fetchUserData();
    }, [checkAuthState]);

    const onInputChange = (event: { target: { value: any } }) => {
        const { value } = event.target;
        setEmail(value);
    };

    const handleButtonClick = () => {
        localStorage.setItem('isNewOnboardingCompleted', 'true');
        window.location.reload();
    };

    return (
        <div className="login-page-container">
            <Row className="login-page">
                <Col className="form-section" lg={4} md={12}>
                    <div
                        className="login-page-logo"
                        onClick={() =>
                            (window.location.href = AppRoutes.Dashboard)
                        }
                    >
                        <img src={icons.graduationBlack} alt="logo" />
                        <h3>
                            Study<span>buddy+</span>
                        </h3>
                    </div>

                    {/* <img src={mr1} alt="mr-1" className="mr-icon" />
                    <img src={mr2} alt="mr-2" className="mr-icon" /> */}
                    {/* <br /> */}
                    <div className="compatible-text-container">
                        <p className="txt">
                            <strong>StudyBuddy</strong> isn't compatible with{' '}
                            <strong>iPad, iPhone, or Safari.</strong>
                        </p>
                    </div>

                    <br />
                    <div className="step-completion-container">
                        <p className="txts">
                            {'Please go to your '}
                            <strong>{'computer'}</strong>{' '}
                            {'to complete this step using this link on'}{' '}
                            <strong>{'Google Chrome:'}</strong>
                        </p>
                        <a
                            href="https://studybuddy.gg/onboarding"
                            className="onboarding-link-button"
                        >
                            https://studybuddy.gg/onboarding
                        </a>
                    </div>

                    {/* <div className="OR-container">
                        <img style={{ width: '100%' }} src={ORImg} alt="OR" />
                    </div>

                    <div className="app-download-container">
                        <img src={DownloadAppImg} alt="download-app" />
                    </div> */}

                    {/* <form>
                        <br />
                        <p className=".lnk">Your login credentials:</p>
                        <br />
                        <div className="form-group">
                            <label htmlFor="email" className="eml">
                                {t('Email')}
                            </label>
                            <div className="input-container">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={onInputChange}
                                    style={inputFieldStyles}
                                />
                                <img
                                    src={eyeIcon}
                                    alt="eye icon"
                                    className="eye-icon"
                                />
                            </div>
                        </div>
                    </form> */}
                    {/* <br />
                    <br /> */}
                    <button
                        className="manage-payment-button-outline"
                        onClick={handleButtonClick}
                    >
                        Go to Dashboard
                    </button>
                </Col>
            </Row>
        </div>
    );
};
