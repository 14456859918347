import React, { useState } from 'react';
import { useAuth } from '~hooks';
import './NewHeader.styles.scss';
import StudybuddyLogoBlack from '../../../assets/studybuddy-logo-black.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NewHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t } = useTranslation();

    const { isAuthenticated } = useAuth();

    const navigate = useNavigate();

    const handleMenuButtonClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const onNavButtonClickHandler = () => {
        if (isAuthenticated) {
            navigate('/dashboard');
        } else {
            navigate('/login');
        }
    };

    return (
        <header className="new-header">
            <div className="new-header-container">
                <div className="new-navbarTop">
                    <a href="/">
                        <img
                            src={StudybuddyLogoBlack}
                            alt="Studybuddy Logo"
                            className="new-navbarBrand"
                        />
                    </a>
                    <button
                        className={isMenuOpen ? 'new-closeIcon' : 'new-menuIcon'}
                        onClick={handleMenuButtonClick}
                    >
                        <img
                            src={
                                isMenuOpen
                                    ? '/assets/images/cross_icon.svg'
                                    : '/assets/images/hamburger_menu.svg'
                            }
                            alt="menu button"
                        />
                    </button>
                </div>

                <div className="new-header-links HideOnMobile">
                    <a href="#howItWorks" className="new-navbarLink">
                        {t('how_it_works')}
                    </a>
                    {/* <a href="#pricing-section" className="new-navbarLink">
                    {t('try_it_out')}
                </a> */}
                    <a href="#newUserReview" className="new-navbarLink">
                        {t('reviews')}
                    </a>
                    <a href="#faq" className="new-navbarLink">
                        {t('FAQ')}
                    </a>
                </div>
                <div className="new-navbarButtons">
                    {!isAuthenticated && (
                        <button
                            className="new-filledButton"
                        >
                            <a href="#cta-container">{t('get_started')}</a>
                        </button>
                    )}
                    <button
                        onClick={onNavButtonClickHandler}
                        className="new-outlinedButton"
                    >
                        {isAuthenticated ? t('dashboard') : t('login')}
                    </button>
                </div>

                <nav className={`new-navbarListMobile ${isMenuOpen ? 'open' : ''}`}>
                    <a
                        href={
                            isAuthenticated ? '/dashboard' : '#pricing-section'
                        }
                        className="new-navbar-shiny-button btn-primary glow new-navbarGetStarted"
                    >
                        {isAuthenticated ? t('dashboard') : t('Try For Free')}
                    </a>
                    <button
                        onClick={onNavButtonClickHandler}
                    >
                        <img
                            className="new-exitIcon"
                            src="/assets/images/exit_icon.svg"
                            alt="exit_icon"
                        />
                        {isAuthenticated ? t('Log Out') : t('login')}
                    </button>
                </nav>
            </div>
        </header>
    );
};
