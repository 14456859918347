import React from 'react';
import DesktopReviews from '~assets/new_reviews.png';
import MobileReviews from '~assets/new_reviews-mobi.png';
import './NewReviews.styles.scss'; // Import the SCSS file for styling

interface NewReviewsProps {
    onImageLoad?: () => void;
}
const NewReviews: React.FC<NewReviewsProps> = ({ onImageLoad }) => {
    return (

        <div id="testimonials-section" className="reviews-section">
            <div className="reviews-content">
                <div className="reviews-image-wrapper">
                    <img
                        src={DesktopReviews}
                        alt="User Reviews"
                        className="reviews-image desktop"
                    />
                    <img
                        src={MobileReviews}
                        loading="eager"
                        fetchPriority="high"
                        alt="User Reviews Mobile"
                        onLoad={onImageLoad}
                        className="reviews-image mobile"
                    />
                </div>
            </div>
        </div>
    );
};

export default NewReviews;
