export const getClassNameByNumber = (num?: number) => {
    let className: string;

    if (!num && num !== 0) {
        return 'text-shade-default';
    }

    if (num >= 0 && num < 13) {
        className = 'text-shade-0';
    } else if (num >= 13 && num < 26) {
        className = 'text-shade-13';
    } else if (num >= 26 && num < 33) {
        className = 'text-shade-26';
    } else if (num >= 33 && num < 42) {
        className = 'text-shade-33';
    } else if (num >= 42 && num < 51) {
        className = 'text-shade-42';
    } else if (num >= 51 && num < 67) {
        className = 'text-shade-51';
    } else if (num >= 67 && num < 75) {
        className = 'text-shade-67';
    } else if (num >= 75 && num < 89) {
        className = 'text-shade-75';
    } else if (num >= 89 && num < 98) {
        className = 'text-shade-89';
    } else if (num >= 98 && num < 100) {
        className = 'text-shade-98';
    } else {
        className = 'text-shade-default';
    }

    return className;
};
