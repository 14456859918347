import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, useDisableBodyScroll } from '~hooks';
import { AppRoutes, CookieKeys } from '~constants';
import { setCookie } from '~utils';
import { firePixelsAddToCart } from '~utils/advertisingEvents';

import { NewHeader } from './NewHeader';
import { NewHero } from './New_Hero';
import { NewNumbers } from './New_Numbers/NewNumbers';
import NewUserReview from './NewUserReview/NewUserReview';
import NewFeature from './New_Feature/NewFeature';
import AiFeature from './AiFeature.tsx/AiFeature';
import { HowItWorks } from '~pages/Homepage/HowItWorks';
import NewCta from './New_Cta/NewCta';
import { NewFooter } from './NewFooter';

import { TrialPopup } from '~components/Modals/TrialPopup';
import { NewsletterPopup } from '~components/Modals/Newsletter';
import { PaymentDetails } from '~pages/PaymentDetails';
import { Register } from '~pages/Register';

export const NewHomepage = () => {
    const [showPopupCheckout, setShowPopupCheckout] = useState(false);
    const [checkoutPage, setCheckoutPage] = useState(false);
    const [isTrialPopupShow, setIsTrialPopupShow] = useState(false);
    const [isNewsLetterPopupShow, setIsNewsLetterPopupShow] = useState(false);

    const { id } = useParams();

    const { updateAuthState, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    useDisableBodyScroll(showPopupCheckout);
    const title = id ? id : 'Coursology';

    useEffect(() => {
        checkReferral();
        checkAuth();
        const TrailTimer = setTimeout(checkIsTrialAvailable, 15000);
        const NewsLetterTimer = setTimeout(checkIsNewsletterAvailable, 60000);
        return () => {
            clearTimeout(TrailTimer);
            clearTimeout(NewsLetterTimer);
        };
    }, []);

    const checkAuth = async () => {
        console.log('priv auth >>>:');
        await updateAuthState();
    };

    const checkReferral = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');
        const fbclid = urlParams.get('fbclid');
        if (ref) {
            setCookie(CookieKeys.referralCookie, ref, 60);
        }
        if (fbclid) {
            setCookie(CookieKeys.fbClickId, fbclid, 60);
        }
    };

    const handlePopupCheckout = () => {
        console.log('isAuthenticated >>>', isAuthenticated);
        setIsTrialPopupShow(false);
        if (isAuthenticated) {
            navigate(AppRoutes.Dashboard);
            return;
        }

        firePixelsAddToCart();
        setShowPopupCheckout(true);
    };

    const handlePopupCheckoutClose = () => {
        setShowPopupCheckout(false);
    };

    const handleCheckoutPage = () => {
        setCheckoutPage(!checkoutPage);
    };

    const handleOpenTrialPopup = () => {
        setIsTrialPopupShow(true);
    };

    const handleCloseTrialPopup = () => {
        localStorage.setItem('isTrialAvailable', 'false');
        setIsTrialPopupShow(false);
    };

    const handleOpenNewsLetterPopup = () => {
        setIsNewsLetterPopupShow(true);
    };

    const handleCloseNewsLetterPopup = () => {
        localStorage.setItem('isNewsletterAvailable', 'false');
        setIsNewsLetterPopupShow(false);
    };

    const checkIsTrialAvailable = () => {
        const isTrialAvailable = localStorage.getItem('isTrialAvailable');
        if (!isTrialAvailable) {
            handleOpenTrialPopup();
        }
    };

    const checkIsNewsletterAvailable = () => {
        const isNewsletterAvailable = localStorage.getItem(
            'isNewsletterAvailable',
        );
        if (!isNewsletterAvailable) {
            handleOpenNewsLetterPopup();
        }
    };

    return (
        <div className="new-homepage">
            <NewHeader />
            <NewHero title={title} />
            <NewNumbers />
            <NewUserReview />
            <NewFeature />
            <AiFeature />
            <HowItWorks />
            <NewCta handleCTA={handlePopupCheckout} />
            <NewFooter />

            {/* Popup Checkout */}
            <div
                className={`popup-checkout ${
                    showPopupCheckout ? 'active' : ''
                } ${checkoutPage ? 'checkout-page' : ''}`}
            >
                {checkoutPage ? (
                    <PaymentDetails isPopupCheckout />
                ) : (
                    <Register
                        isPopupCheckout
                        handleCheckoutPage={handleCheckoutPage}
                    />
                )}
            </div>
            {showPopupCheckout && (
                <div
                    className="popup-checkout-overlay"
                    onClick={handlePopupCheckoutClose}
                ></div>
            )}

            {/* Trial Popup */}
            <TrialPopup
                show={isTrialPopupShow && !showPopupCheckout}
                handleClose={handleCloseTrialPopup}
                handleCTA={handlePopupCheckout}
            />

            {/* Newsletter Popup */}
            <NewsletterPopup
                show={isNewsLetterPopupShow && !showPopupCheckout}
                handleClose={handleCloseNewsLetterPopup}
            />
        </div>
    );
};
