import React, { useState } from 'react';
import { WriterDashboardSidebar } from './Sidebar/Sidebar';
import { Outlet } from 'react-router-dom';

import './WriterDashboardWrapper.styles.scss';

export const WriterDashboardWrapper = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleCTA = () => {
        console.log('handleCTA called >>>');
    };

    return (
        <div className="writer-dashboard">
            <WriterDashboardSidebar />
            <div className='writer-dashboard-container'>
                <Outlet />
            </div>
        </div>
    );
};
