import React from 'react';
import IconWithText, { Status } from '~components/IconWithText/IconWithText';
import AiLikeLiHoodPercentage from '../AiLikeliHoodPercentage/AiLikeliHoodPercentage';

import './AiDetectorFooter.styles.scss';
interface AiDetectorFooterProps {
    status: Status;
    percentage?: number;
}

const AiDetectorFooter: React.FC<AiDetectorFooterProps> = ({
    percentage,
    status,
}) => {
    return (
        <div className="ai-detector-footer">
            <div className="ai-detector-footer-top">
                <span>AI Detection Likelihood:</span>
                <div className="ai-detector-footer-top-right">
                    <IconWithText
                        status="UNDETECTED"
                        text="Human"
                        isBold={true}
                    />
                    <IconWithText
                        status="WARNING"
                        text="Likely AI"
                        isBold={true}
                    />
                    <IconWithText status="DETECTED" text="AI" isBold={true} />
                </div>
            </div>
            <div className="ai-detector-footer-bottom">
                <div className="ai-detector-footer-bottom-left">
                    <AiLikeLiHoodPercentage num={percentage} />
                </div>
                <div className="ai-detector-footer-bottom-right">
                    <IconWithText status={status} text="GPTzero" />
                    <IconWithText status={status} text="Turnitin" />
                    <IconWithText status={status} text="Writer " />
                    <IconWithText status={status} text="OpenAI" />
                    <IconWithText status={status} text="Copyleaks" />
                    <IconWithText status={status} text="Content at Scale" />
                </div>
            </div>
        </div>
    );
};

export default AiDetectorFooter;
