import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './StickyPricing.styles.scss';

type PricingProps = {
    handleCTA: () => void;
    showCheckout: boolean;
    isPricingInView: boolean;
};

export const StickyPricing: FC<PricingProps> = ({
    handleCTA,
    showCheckout,
    isPricingInView,
}) => {
    const onOldPricingClickHandler = () => {
        handleCTA();
    };

    const [scrollPosition, setScrollPosition] = useState(0);
    const [minutes, setMinutes] = useState(59);
    const [seconds, setSeconds] = useState(59);
    const { t } = useTranslation();

    const deadline = new Date();
    deadline.setHours(deadline.getHours() + 2);

    const getTime = () => {
        const time = deadline.getTime() - Date.now();

        if (minutes === 0 && seconds === 0) {
            setMinutes(59);
            setSeconds(59);
        } else {
            setMinutes(Math.floor((time / 1000 / 60) % 60));
            setSeconds(Math.floor((time / 1000) % 60));
        }
    };

    const scrollChange = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollChange);
        const interval = setInterval(() => getTime(), 1000);

        return () => {
            window.removeEventListener('scroll', scrollChange);
            clearInterval(interval);
        };
    }, []);

    if (showCheckout) {
        return null;
    }

    if (isPricingInView) {
        return null;
    }

    return (
        <div id="stickyPricingContainer">
            <button
                onClick={onOldPricingClickHandler}
                className="pricing-shiny-button"
            >
                {t('try_for')}
                {' $0.99'}!
            </button>
        </div>
    );
};
