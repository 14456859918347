import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAuth, useScreenSize } from '~hooks';
import { useExtensionHelper } from '~utils/extension';
import DashboardKnowledges from '~components/Dashboard/DashboardKnowledges/DashboardKnowledges';
import { HowToUse } from '~components/Dashboard/HowToUse/HowToUse';
import { Onboarding } from '~components/Onboarding';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DashboardUserInfo from '~components/Dashboard/DashboardUserInfo/DashboardUserInfo';
import { Banner } from '~components/Banner';
import { DashboardFooter } from '~components/Dashboard/DashboardFooter';
import { MobileOnboard } from '~components/mobileOnboard'; // Import MobileOnboard

import './DashboardOverview.styles.scss';
import { API_NAME, STUDYBUDDY_AI_CHAT_API } from '~constants';
import { SemesterDealPopup } from '~components/Modals/SemesterDealPopup';

export const DashboardOverview = ({
    onboardingStatus,
}: {
    onboardingStatus: boolean;
}) => {
    const { isMobile } = useScreenSize();
    const { isExtensionInstalled } = useExtensionHelper();
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [isSemesterDealPopupShow, setIsSemesterDealPopupShow] =
        useState(true);
    const { token } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onHelpButtonClick = () => {
        navigate('/dashboard/contact');
    };

    const onInstallExtensionClickHandler = () => {
        setShowOnboarding(true);
    };

    const onTestFunction = () => {
        console.log('test function clicked >>>');
        testFuncApi(token);
    };

    const onSemesterDealPopupCloseHandler = () => {
        setIsSemesterDealPopupShow(false);
    };

    useEffect(() => {
        const isOnboardingCompleted = localStorage.getItem(
            'isNewOnboardingCompleted',
        );
        if (!isOnboardingCompleted && !isExtensionInstalled) {
            setShowOnboarding(true);
        } else {
            setShowOnboarding(false);
        }
    }, [isExtensionInstalled]);

    useEffect(() => {
        if (onboardingStatus) {
            setShowOnboarding(true);
        }
    }, [onboardingStatus]);

    useEffect(() => {
        (window as any).botpressWebChat.mergeConfig({ hideWidget: false });
    }, []);

    useEffect(() => {
        const randomNumber = Math.floor(Math.random() * 10) + 1;
        console.log('number', randomNumber);
        console.log(isSemesterDealPopupShow);
        setIsSemesterDealPopupShow(randomNumber >= 3 && randomNumber <= 6);
    }, []);

    if (isMobile && showOnboarding) {
        return <MobileOnboard />;
    }

    if (showOnboarding) {
        return (
            <Onboarding showOnboarding setShowOnboarding={setShowOnboarding} />
        );
    }

    const testFuncApi = async (token: string | undefined) => {
        if (!token) {
            return;
        }
        try {
            const response = await API.post(API_NAME, STUDYBUDDY_AI_CHAT_API, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: {
                    data: [
                        {
                            role: 'user',
                            content: 'Hi',
                        },
                        {
                            role: 'assistant',
                            content: 'Hi',
                        },
                        {
                            role: 'user',
                            content: 'What is nodejs?',
                        },
                        {
                            role: 'assistant',
                            content:
                                "Node.js is a JavaScript runtime built on Chrome's V8 JavaScript engine.",
                        },
                        {
                            role: 'user',
                            content: 'Who invented this?',
                        },
                    ],
                },
            });

            console.log('responmse --------->', response);
        } catch (err: any) {
            console.log('error -------------->', err);

            console.error(err);
        }
    };

    return (
        <>
            <Container style={{ maxWidth: '1140px' }}>
                {/* TODO : If user is already subscribed to semester plan, don't show this popup */}
                <SemesterDealPopup
                    show={isSemesterDealPopupShow}
                    handleClose={onSemesterDealPopupCloseHandler}
                />
                <div className="dashboard-overview">
                    <div className="dashboard-overview__content">
                        <Banner />
                        {/* <button onClick={() => testFuncApi(token)}>Test button</button> */}
                        <div className="dashboard-body">
                            <DashboardUserInfo
                                showOnboarding={showOnboarding}
                                setShowOnboarding={setShowOnboarding}
                            />
                            <DashboardKnowledges
                                showOnboarding={showOnboarding}
                                setShowOnboarding={setShowOnboarding}
                            />
                        </div>
                    </div>
                </div>
                <HowToUse videoMaxSize="100%" />
            </Container>
            <DashboardFooter />
        </>
    );
};
