import React, { FC } from 'react';

import './CTA.styles.scss';
import { useTranslation } from 'react-i18next';
import { useInView } from '~hooks';

export type CTAProps = {
    handleCTA: () => void;
    setIsPricingInView?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CTA: FC<CTAProps> = (props) => {
    const { handleCTA, setIsPricingInView } = props;
    const { t } = useTranslation();

    const onPricingClickHandler = () => {
        handleCTA();
    };

    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    React.useEffect(() => {
        setIsPricingInView?.(inView);
    }, [inView, setIsPricingInView]);

    return (
        <div className="cta-section">
            <div className="cta-box">
                {/* <div className="price-tag">
                    <p>{t('72_hour_access')}</p>
                </div> */}
                <h1>
                    <span>{t('try_studybuddy_for')}</span> {'$0.99'}
                </h1>
                {/* <p>{t('join_530k+_students')}</p> */}
                <button
                    ref={ref as React.LegacyRef<HTMLButtonElement>}
                    type="button"
                    className="cta-button"
                    onClick={onPricingClickHandler}
                >
                    {t('get_started')}
                    {/* {t('try_now')} */}
                </button>
            </div>
        </div>
    );
};
