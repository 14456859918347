import axios, { AxiosResponse } from 'axios';

// Define a common function to handle POST requests with authorization
export const postWithAuth = async <T>(
    url: string, 
    data: T, 
    token: string
): Promise<AxiosResponse> => {
    try {
        const response = await axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${token}`, // Authorization header
                'Content-Type': 'application/json', // Optional, but often necessary
            },
        });
        return response;  // Return the entire response object
    } catch (error: any) {
        console.error('Error making POST request:', error);
        throw error;  // Rethrow the error to handle it in the calling function
    }
};
