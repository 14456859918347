import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView, useScreenSize } from '~hooks';
import { getDayAfterTwoDays } from '~utils';
import './Pricing.styles.scss';
import PricePoint from './PricingPoint';
import PriceDisplay from './PriceDisplay';
import PlanToggle from './PlanToggle';
import { icons } from '~constants';
type PlanType = 'weekly' | 'three-month';

interface PricingProps {
    handleCTA: () => void;
    setIsPricingInView?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Pricing: React.FC<PricingProps> = ({
    handleCTA,
    setIsPricingInView,
}) => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();
    const [activeplan, setActivePlan] = useState<PlanType>('weekly');
    const { ref, inView } = useInView({ threshold: 0.1 });

    useEffect(() => {
        setIsPricingInView?.(inView);
    }, [inView, setIsPricingInView]);

    const pricingOfferEndDate = getDayAfterTwoDays();

    return (
        <div id="writerToolPricingSection">
            <div id="writerToolPrice">
                <h1 id="writerToolPriceTitle">StudyBuddy {t('pricing')}</h1>
                <h1 id="writerToolPriceTitleMobile">
                    StudyBuddy {t('pricing')}
                </h1>

                <PlanToggle
                    activeplan={activeplan}
                    setActivePlan={setActivePlan}
                />

                <div
                    id={
                        isMobile
                            ? 'writerToolPriceMobileContainerNew'
                            : 'writerToolPriceMobileContainerNew'
                    }
                >
                    <div id={isMobile ? '' : 'writerToolPricingDesktop'}>
                        <div id="writerToolPriceTaglineDesktop">
                            {activeplan === 'weekly' ? (
                                <p>
                                    {t(
                                        isMobile
                                            ? '72_hours_a'
                                            : '72_unlimited',
                                    )}{' '}
                                    <span>
                                        {t(
                                            isMobile
                                                ? 'unlimited_access'
                                                : 'access_studybuddy',
                                        )}
                                    </span>
                                </p>
                            ) : (
                                <p>
                                    {t('save_33')}{' '}
                                    <span>{t('semester_plan_caps')}</span>
                                </p>
                            )}
                        </div>

                        <PriceDisplay activeplan={activeplan} t={t} />

                        <button
                            type="button"
                            ref={ref as React.RefObject<HTMLButtonElement>}
                            className="writerToolCtaButtonTryNow"
                            onClick={handleCTA}
                        >
                            {t('try_now')}
                        </button>

                        <div id="writerToolPricePoints">
                            <PricePoint
                                text="Advanced"
                                boldText={t('AI Detection Bypass')}
                            />
                            <PricePoint
                                text={t('Untraceable')}
                                boldText={t('AI-Generated Text')}
                            />
                            <PricePoint
                                text={t('instant')}
                                boldText={t('Essay Writer')}
                            />
                            <PricePoint
                                text={t('Plagiarism-Free and ')}
                                boldText={t('Undetectable')}
                                isInvertBold
                            />
                            <PricePoint
                                text={t('Humanize')}
                                boldText={t('Your AI Text')}
                            />
                            <PricePoint
                                text={t('530K+')}
                                boldText={t('students')}
                                isInvertBold
                            />
                        </div>
                        {isMobile && (
                             <hr className="writerToolHrLine" />
                        )}
                        <div id="writerToolPriceBenefits">
                            {t(
                                isMobile
                                    ? 'cancel_anytime'
                                    : 'cancel_anytime_2',
                            )}
                            {'5.99'}
                            {t('per_week')}
                        </div>
                    </div>

                    {!isMobile && (
                             <hr className="writerToolHrLine" />
                        )}

                    <div id="writerToolPaymentMethodContainer">
                        <div className="writerToolCardsContainer">
                            <img src={icons.visaBordered} alt="visa" />
                            <img src={icons.mastercardBordered} alt="ms" />
                            <img src={icons.amexBordered} alt="amex" />
                            <img className="gpay" src={icons.gpayBordered} alt="gpay" />
                            <img src={icons.applepayBordered} alt="applepay" />
                        </div>
                        {!isMobile && (
                            <img src={icons.ques_ans_light} alt="TenMQues" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
