import React from "react";
import { PlanType } from "./PriceDisplay";
import { Assets, icons } from "~constants";

interface PlanToggleProps {
    activeplan: PlanType;
    setActivePlan: React.Dispatch<React.SetStateAction<PlanType>>;
}

const PlanToggle: React.FC<PlanToggleProps> = ({
    activeplan,
    setActivePlan,
}) => (
    <div className="writerToolPlanToggleContainer">
        <div className="writerToolPricingDiscountContainer">
            <img
                src={Assets.save45}
                alt="pricing-discount"
                className="writerToolPriceDiscountImage"
            />
            <img
                src={icons.arrowRightTilt}
                id="right-arrow-tilt"
                alt="right-arrow"
            />
        </div>
        <div className="writerToolPlanToggle">
            <button
                className={`writerToolPlanButton ${activeplan === 'weekly' ? 'active' : ''}`}
                onClick={() => setActivePlan('weekly')}
            >
                Weekly
            </button>
            <button
                className={`writerToolPlanButton ${activeplan === 'three-month' ? 'active' : ''}`}
                onClick={() => setActivePlan('three-month')}
            >
                3-Month
            </button>
            <div className={`writerToolSlider ${activeplan}`}></div>
        </div>
    </div>
);
export default PlanToggle