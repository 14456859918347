export const FAQItems = [
    {
        question: 'writer.faq1',
        answer: `writer.faq1_answer`,
    },
    {
        question: 'writer.faq2',
        answer: `writer.faq2_answer`,
    },
    {
        question: 'writer.faq3',
        answer: `writer.faq3_answer`,
    },
    {
        question: 'writer.faq4',
        answer: `writer.faq4_answer`,
    },
    {
        question: 'writer.faq5',
        answer: `writer.faq5_answer`,
    },
    {
        question: 'writer.faq6',
        answer: `writer.faq6_answer`,
    },
];
