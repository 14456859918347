export const institutionsItem = [
    '/assets/images/institutions/svgs/harvard-logo-light.svg',
    '/assets/images/institutions/svgs/mcgill-logo-light.svg',
    '/assets/images/institutions/svgs/MIT-logo-light.svg',
    '/assets/images/institutions/svgs/chicago-uni-logo-light.svg',
    '/assets/images/institutions/svgs/USC-logo-light.svg',
    '/assets/images/institutions/svgs/princeton-logo-light.svg',
    '/assets/images/institutions/svgs/Notheastern-logo-light.svg',
    '/assets/images/institutions/svgs/wisconsin-logo-light.svg',
    '/assets/images/institutions/svgs/john-hokins-logo-light.svg',
    '/assets/images/institutions/svgs/berkeley-logo-light.svg',
    '/assets/images/institutions/svgs/stanford-logo-light.svg',
    '/assets/images/institutions/svgs/texas-logo-light.svg',
    '/assets/images/institutions/svgs/michigan-logo-light.svg',
];
