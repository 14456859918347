import React from 'react';
import { Pagination } from 'swiper/modules';
import SwiperCore from 'swiper';
import { useTranslation } from 'react-i18next';

import './HowItWorks.styles.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { Assets } from '~constants';

SwiperCore.use([Pagination]);

export const HowItWorks = () => {
    const { t } = useTranslation();

    return (
        <div id="howItWorks">
            <h1 className="howItWorksTitle">{t('wp_how_it_works')}</h1>
            <div className="howItWorksContainer howItWorksMobileContainer">
                <div
                    style={{ justifySelf: 'self-end' }}
                    className="howItWorksItem"
                >
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>{t('undetectable_essay_writer')}</h3>
                            <p>
                                {t('undetectable_essay_writer_desc1')}{' '}
                                <span>
                                    {t('undetectable_essay_writer_desc2')}
                                </span>{' '}
                                {t('undetectable_essay_writer_desc3')}
                            </p>
                        </div>
                        <img src={Assets.essayWriting} />
                    </div>
                </div>

                <div className="howItWorksItem">
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>{t('humanize_ai_written_text')}</h3>
                            <p>
                                <span>
                                    {t('humanize_ai_written_text_desc1')}{' '}
                                </span>
                                {t('humanize_ai_written_text_desc2')}
                            </p>
                        </div>
                        <img src={Assets.humanizeWriting} />
                    </div>
                </div>

                <div
                    style={{
                        justifySelf: 'self-end',
                    }}
                    className="howItWorksItem marginTopItem"
                >
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>{t('cite_any_major_format')}</h3>
                            <p>
                                <strong>
                                    {t('cite_any_major_format_desc1')}
                                </strong>{' '}
                                {t('cite_any_major_format_desc2')}
                            </p>
                        </div>
                        <img src={Assets.citeFormate} />
                    </div>
                </div>

                <div className="howItWorksItem marginTopItem">
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>{t('write_just_like_you')}</h3>

                            <p>
                                <span>{t('write_just_like_you_desc1')}</span>
                                {', '}
                                {t('write_just_like_you_desc2')}
                            </p>
                        </div>
                        <img src={Assets.writeLikeYou} />
                    </div>
                </div>
            </div>
        </div>
    );
};
