import { Modal } from 'react-bootstrap';

import './BlurBackdropPopup.styles.scss';

interface BlurBackdropPopupProps {
    show: boolean;
    handleClose: () => void;
    children: React.ReactNode;
}

const BlurBackdropPopup: React.FC<BlurBackdropPopupProps> = ({
    show,
    handleClose,
    children,
}) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="blurModal"
            size="xl"
            centered
        >
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
};

export default BlurBackdropPopup;
