import React, { FC } from 'react';
import './NewCta.styles.scss'; // Import the SCSS file for styling
import { t } from 'i18next';

export type NewCtaProps = {
    handleCTA: () => void;
};

export const NewCta: FC<NewCtaProps> = ({ handleCTA }) => {
    const onPricingClickHandler = () => {
        handleCTA();
    };

    return (
        <div id="cta-container">
            <div className="cta-card">
                <h3>
                    Try StudyBuddy for <span className="blu">$0.99</span>
                </h3>
                <p>
                    {t(
                        'Join the 530K+ students who are benefiting from StudyBuddy',
                    )}
                </p>
                <button className="new-cta-btn" onClick={onPricingClickHandler}>
                    {t('try_now')}
                </button>
            </div>
        </div>
    );
};

export default NewCta;
