import { useMemo, useState } from 'react';
import './WriterTextHumanizer.styles.scss';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import scannerIcon from '~/assets/scanner.svg';
import HumanzedResultContent from './HumanzedResultContent';
import { ContentTextArea } from '~components/ContentTextArea/ContentTextArea';
import ContentTextAreaInput from '~components/ContentTextArea/ContentTextAreaInput';
import CheckForAi from './CheckForAi/CheckForAi';

export const WriterTextHumanizer = () => {
    const [text, setText] = useState<string>('');
    const [humanizedText, setHumanizedText] = useState<string>('');

    const [showPopup, setShowPopup] = useState(false);

    const onTextUpdate = (text: string) => {
        setText(text);
    };

    let wordCount = useMemo(() => {
        if (text) {
            return text?.trim().split(/\s+/).filter(Boolean)?.length;
        } else {
            return 0;
        }
    }, [text]);

    return (
        <>
            <header>
                <h1 className="dashboard-title">AI Text Humanizer</h1>
            </header>

            <div className="aiTextHumanizerParent main">
                <span className="textHumanizerHeader">
                    Waiting for your input...
                </span>

                <div className="aiTextHumanizerInnerContainer">
                    <div className="textHumanizerLeftContainer aiTextHumanizerItem">
                        <ContentTextArea onTextChange={onTextUpdate} />

                        <div className="textHumanizerLeftContainerBottom">
                            <div className="wordCount">
                                {`${wordCount}  words`}
                            </div>
                            <div className="humanizeText">
                                <button
                                    className="humanizeButton"
                                    onClick={() => {
                                        if (text.length === 0) {
                                            alert(
                                                'Please write some text first',
                                            );
                                        } else {
                                            setHumanizedText(text);
                                        }
                                    }}
                                >
                                    <NinjaIcon />
                                    Humanize All Text
                                </button>
                                <button
                                    className="aiButton"
                                    onClick={() => setShowPopup(true)}
                                    disabled={!wordCount}
                                >
                                    <img
                                        src={scannerIcon}
                                        height="16px"
                                        width="16px"
                                        alt="not found "
                                    />
                                    Check for Ai
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="textHumanizerRightContainer aiTextHumanizerItem">
                        {humanizedText?.length > 0 ? (
                            <ContentTextAreaInput
                                text={humanizedText}
                                disable={true}
                            />
                        ) : (
                            <p className="ai-text-header">
                                Your humanized content will appear here.
                            </p>
                        )}
                        <HumanzedResultContent text={humanizedText} />
                    </div>
                </div>
                <CheckForAi
                    handleClose={() => setShowPopup(false)}
                    showPopup={showPopup}
                    onTextUpdate={onTextUpdate}
                    text={text}
                    onHumanizeText={() => {
                        setHumanizedText(text);
                        setShowPopup(false);
                        
                    }}
                />
            </div>
        </>
    );
};
