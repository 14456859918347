import { Pagination } from 'swiper/modules';
import SwiperCore from 'swiper';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '~hooks';
import { icons } from '~constants';

import './WriterFeatures.styles.scss';


SwiperCore.use([Pagination]);

export const WriterFeatures = () => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    return (
        <div id="WriterFeatures">
            <h1 className="mainTitle">{t('fourFeatures.title')}</h1>
            <div className="WriterFeaturesContainer WriterFeaturesMobileContainer">
                <div
                    style={{ justifySelf: 'self-end' }}
                    className="WriterFeaturesItem"
                >
                    <div className="itemContent">
                        
                            <h3 className="heading">
                            <img src={icons.robot} />
                                {t('fourFeatures.features.humanizeAI.heading')}
                            </h3>
                            <p>
                                {t(
                                    'fourFeatures.features.humanizeAI.description',
                                )}
                            </p>
                        </div>
                    
                </div>

                <div className="WriterFeaturesItem">
                    <div className="itemContent">
                       
                            <h3 className="heading">
                                <img src={icons.magnifyingGlass} />
                                {t(
                                    'fourFeatures.features.plagiarismFree.heading',
                                )}
                            </h3>
                            <p>
                                {t(
                                    'fourFeatures.features.plagiarismFree.description',
                                )}
                            </p>
                        </div>
                    </div>
          

                <div
                    style={{
                        justifySelf: 'self-end',
                    }}
                    className="WriterFeaturesItem"
                >
                    <div className="itemContent">
                        
                            <h3 className="heading">
                                <img src={icons.policeOfficer} />
                                {t(
                                    isMobile? 'fourFeatures.features.bypassDetectors.heading_mobile' : 'fourFeatures.features.bypassDetectors.heading',
                                )}
                            </h3>
                            <p>
                                {t(
                                    'fourFeatures.features.bypassDetectors.description',
                                )}
                            </p>
                        </div>
                    </div>
                

                <div className="WriterFeaturesItem">
                    <div className="itemContent">
                        
                            <h3 className="heading">
                                <img src={icons.ninja} />
                                {t(
                                    'fourFeatures.features.undetectable.heading',
                                )}
                            </h3>

                            <p>
                                {t(
                                    'fourFeatures.features.undetectable.description',
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        
    );
};
