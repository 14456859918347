import React from 'react';
import { ReactComponent as NinjaIcon } from '~/assets/ninja-icon.svg';
import './AiDetectorHeader.styles.scss';

interface AiDetectorHeaderProps {
    text?: string;
    isInDetectMode: boolean;
    setIsInDetectMode: React.Dispatch<React.SetStateAction<boolean>>;
    toggleIsDetected: () => void;
}

export function AiDetectorHeader({
    isInDetectMode,
    setIsInDetectMode,
    text,
    toggleIsDetected,
}: Readonly<AiDetectorHeaderProps>) {
    const humanizeButtonText = isInDetectMode
        ? 'Humanize All Text'
        : 'Detect AI';

    return (
        <div className="ai-detector-header">
            {isInDetectMode ? (
                <button
                    className="backButton"
                    disabled={!isInDetectMode}
                    onClick={() => {
                        setIsInDetectMode(false);
                    }}
                >
                    Back
                </button>
            ) : (
                <div />
            )}
            {isInDetectMode && (
                <button
                    className="humanizeButton"
                    disabled={!text}
                    onClick={() => {
                        toggleIsDetected();
                    }}
                >
                    <NinjaIcon />
                    Toggle View
                </button>
            )}
            <button
                className="humanizeButton"
                disabled={!text}
                onClick={() => {
                    setIsInDetectMode(true);
                }}
            >
                <NinjaIcon />
                {humanizeButtonText}
            </button>
        </div>
    );
}
