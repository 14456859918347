import React, { FC, useEffect, useState } from 'react';
import './CountDown.styles.scss';

type Props = {
    limit: number;
};

export const CountDown: FC<Props> = ({ limit }) => {
    const [seconds, setSeconds] = useState(limit);

    useEffect(() => {
        if (seconds > 0) {
            const timer = setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [seconds]);

    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;

    const formatNumber = (number: number): string =>
        number < 10 ? `0${number}` : number.toString();

    const minutesString = formatNumber(minutes);
    const secondsString = formatNumber(secondsLeft);

    return (
        <div className="countdown">
            <span className="digit">{minutesString.charAt(0)}</span>
            <span className="digit">{minutesString.charAt(1)}</span>
            <span className="separator">:</span>
            <span className="digit">{secondsString.charAt(0)}</span>
            <span className="digit">{secondsString.charAt(1)}</span>
        </div>
    );
};
