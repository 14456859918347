import React, { FC } from 'react';

import './Hero.styles.scss';
import {ReactComponent as CheckIcon} from '~assets/check-icon-black.svg';
import { useTranslation } from 'react-i18next';
import { useScreenSize, useInView } from '~hooks';
import { Assets } from '~constants';

interface HeroProps {
    setIsPricingInView?: React.Dispatch<React.SetStateAction<boolean>>;
    scrollToPricing?: () => void;
}

export const Hero: FC<HeroProps> = ({
    setIsPricingInView,
    scrollToPricing,
}) => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();
    const { ref, inView } = useInView({ threshold: 0.1 });

    React.useEffect(() => {
        setIsPricingInView?.(inView);
    }, [inView, setIsPricingInView]);

    return (
        <div id="hero">
            {/* <img
                id="heroRightGradientMobile"
                src={HeroRightGradientMobile}
                alt="hero_right_gradient_mobile"
            /> */}
            <div id="heroInner">
                <div id="heroLeft">
                    {isMobile ? (
                        <div id="heroTrustContainerMobile">
                            <img
                                src={Assets.trustPilotWriterMobile}
                                alt="trustPilotLogo"
                            />
                        </div>
                    ) : (
                        <div id="heroTrustContainer">
                            <img
                                src={Assets.peopleGroup}
                                alt="PeopleGroupLogo"
                                height="30px"
                            />
                            <img
                                src={Assets.trustPilotWriter}
                                alt="trustPilotLogo"
                            />
                        </div>
                    )}

                    <h1 id="heroTitle">
                        {/* {t('instant_answers')} */}
                        {t('generate_eassy')}
                        {/* <br /> <span>{t('hero_title_2')}</span> */}
                        <br />{' '}
                    </h1>
                    {/* <p className="hero-description">{t('hero_desc')}</p> */}

                    {/* desktop version */}
                    <ul id="heroFeatures">
                        <div className="feature-list-container">
                            <CheckIcon />
                            <li className="heroFeature">
                                {t('one_click')}
                                {', '}
                                <b>{t('undetectable_essay_writing')}</b>
                            </li>
                        </div>

                        <div className="feature-list-container">
                            <CheckIcon />
                            <li className="heroFeature">
                                {/* {t('incognito_stealth')} */}
                                {t('stay_safe')} {' & '}
                                <b>{t('bypass_ai_detectors')}</b>
                            </li>
                        </div>

                        <div className="feature-list-container">
                            <CheckIcon />
                            <li className="heroFeature">
                                {t('turn_ai_text')}{' '}
                                <b>{t('human_like_essays')} </b>
                            </li>
                        </div>

                        <div className="feature-list-container">
                            <CheckIcon />
                            <li className="heroFeature">
                                {t('match_our_writing')}
                                {', '}
                                <b>{t('no_one_will_know')}</b>
                            </li>
                        </div>
                    </ul>
                    {!isMobile && (
                        <a onClick={scrollToPricing}>
                            <div className="hero-cta-btn-writer">
                                {' '}
                                {t('try_now')}
                            </div>
                        </a>
                    )}
                </div>
                <div id="heroRight">
                    <div id="heroRightVideo">
                        <video
                            id="heroVideo"
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                            poster={Assets.WriterHeroVideoPoster}
                            
                        >
                            <source
                                src={Assets.WriterHeroVideo}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    {/* <p className="HideOnMobile" id="heroTagline">
                        {' '}
                        AI {t('chrome_extension')}:{' '}
                    </p> */}

                    {/* mobile version */}
                    <ul id="heroFeaturesMobile">
                        <li className="heroFeatureMobile noMarginTop">
                            <CheckIcon />
                            {t('one_click')}
                            {', '}
                            <b>{t('undetectable_essay_writing')}</b>
                        </li>
                        <li className="heroFeatureMobile">
                            <CheckIcon />
                            {/* {t('incognito_stealth')} */}
                            {t('stay_safe')} {' & '}
                            <b>{t('bypass_ai_detectors')}</b>
                        </li>

                        <li className="heroFeatureMobile">
                            <CheckIcon />
                            {t('turn_ai_text')} <b>{t('human_like_essays')} </b>
                        </li>
                        <li className="heroFeatureMobile">
                            <CheckIcon />
                            {t('match_our_writing')}{', '}
                            <b>{t('no_one_will_know')}</b>
                        </li>
                    </ul>
                    {isMobile && (
                        <a onClick={scrollToPricing}>
                            <div
                                ref={ref as React.LegacyRef<HTMLDivElement>}
                                className="hero-cta-btn-writer"
                            >
                                {' '}
                                {t('try_now')}
                            </div>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};
