import React from 'react';
import {ReactComponent as CheckIcon} from '~assets/check-icon-black.svg';
interface PricePointProps {
    text: string;
    boldText: string;
    isInvertBold?:boolean;
}

const PricePoint: React.FC<PricePointProps> = ({ text, boldText, isInvertBold }) => (
    <div className="writerToolPricePoint">
        <CheckIcon />
        {isInvertBold ? <p>
            <span>{text}</span>{boldText}
        </p>:
        <p>
        {text} <span>{boldText}</span>
    </p>
    }
    </div>
);

export default PricePoint;
