import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Header } from './Header';
import { Hero } from './Hero';
import { Institutions } from './Institutions';
import { PictureReviews } from './PictureReviews';
import { Pricing } from './Pricing';
import { HowItWorks } from './HowItWorks';
import { Features } from './Features';
import { CompanyLogos } from './CompanyLogos';
import { Faq } from './Faq';
import { Footer } from './Footer';
import { StickyPricing } from './StickyPricing';

import { Register } from '~pages/Register';
import { PaymentDetails } from '~pages/PaymentDetails';
import {
    useAuth,
    useDisableBodyScroll,
    useScreenSize,
    useScrollIntoView,
} from '~hooks';
import { AppRoutes, CookieKeys } from '~constants';

import { firePixelsAddToCart } from '~utils/advertisingEvents';
import { TrialPopup } from '~components/Modals/TrialPopup';
import { NewsletterPopup } from '~components/Modals/Newsletter';
import { setCookie } from '~utils';

import { ChatGPTSection } from '~pages/Homepage/ChatGPTSection/ChatGPTSection';
import { CTA } from '~pages/Homepage/CTA/CTA';
import { Testimonials } from './Testimonials';

import './Homepage.styles.scss';
import { Numbers } from './Numbers';
import { DisturbancePopup } from '~components/Modals/DisturbancePopup';
import { Tools } from './ToolsSection';
import NewFeature from './NewFeatures/NewFeature';
import NewReviews from './NewReviews/NewReviews';

export const Homepage = () => {
    const [showPopupCheckout, setShowPopupCheckout] = useState(false);
    const [checkoutPage, setCheckoutPage] = useState(false);
    const [isTrialPopupShow, setIsTrialPopupShow] = useState(false);
    const [isNewsLetterPopupShow, setIsNewsLetterPopupShow] = useState(false);

    // const [disturbancePopup, setDisturbancePopup] = useState(false);

    const { updateAuthState, isAuthenticated } = useAuth();
    const { isMobile } = useScreenSize();

    const checkAuth = async () => {
        console.log('priv auth >>>:');
        await updateAuthState();
    };

    const checkReferral = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');
        const fbclid = urlParams.get('fbclid');
        if (ref) {
            setCookie(CookieKeys.referralCookie, ref, 60);
        }
        if (fbclid) {
            setCookie(CookieKeys.fbClickId, fbclid, 60);
        }
    };

    const navigate = useNavigate();
    useDisableBodyScroll(showPopupCheckout);

    const handlePopupCheckout = () => {
        console.log('isAuthenticated >>>', isAuthenticated);
        setIsTrialPopupShow(false);
        if (isAuthenticated) {
            navigate(AppRoutes.Dashboard);
            return;
        }

        firePixelsAddToCart();

        setShowPopupCheckout(true);
    };

    const handlePopupCheckoutClose = () => {
        setShowPopupCheckout(false);
    };

    const handleCheckoutPage = () => {
        setCheckoutPage(!checkoutPage);
    };

    const handleOpenTrialPopup = () => {
        setIsTrialPopupShow(true);
    };

    const handleCloseTrialPopup = () => {
        localStorage.setItem('isTrialAvailable', 'false');
        setIsTrialPopupShow(false);
    };

    const handleOpenNewsLetterPopup = () => {
        setIsNewsLetterPopupShow(true);
        setIsTrialPopupShow(false);
    };

    const handleCloseNewsLetterPopup = () => {
        localStorage.setItem('isNewsletterAvailable', 'false');
        setIsNewsLetterPopupShow(false);
    };

    const checkIsTrialAvailable = () => {
        const isTrialAvailable = localStorage.getItem('isTrialAvailable');
        if (!isTrialAvailable) {
            handleOpenTrialPopup();
        }
    };

    const checkIsNewsletterAvailable = () => {
        const isNewsletterAvailable = localStorage.getItem(
            'isNewsletterAvailable',
        );
        if (!isNewsletterAvailable) {
            handleOpenNewsLetterPopup();
        }
    };

    /* const disturbancePopupHandler = () => {
        setDisturbancePopup(true);
    };

    const disturbancePopupCloseHandler = () => {
        setDisturbancePopup(false);
    }; */

    useEffect(() => {
        checkReferral();
        checkAuth();
        const TrialTimer = setTimeout(checkIsTrialAvailable, 10000);
        const NewsLetterTimer = setTimeout(checkIsNewsletterAvailable, 60000);
        if (isNewsLetterPopupShow) {
            clearTimeout(TrialTimer);
            if (isTrialPopupShow) {
                setIsTrialPopupShow(false);
            }
        }
        /* const DisturbanceTimer = setTimeout(disturbancePopupHandler, 500); */
        return () => {
            clearTimeout(TrialTimer);
            clearTimeout(NewsLetterTimer);
        };
    }, []);

    const [isPricingInView, setIsPricingInView] = useState(false);

    const scrollToPricing = () => {
        let pricingEle = document.getElementById('pricing-section');
        pricingEle?.scrollIntoView({ behavior: 'smooth' });
    };

    const { toggleRender } = useScrollIntoView(300);

    return (
        <div className="homepage">
            <Header scrollToPricing={scrollToPricing} />
            <Hero
                setIsPricingInView={setIsPricingInView}
                scrollToPricing={scrollToPricing}
            />
            <CompanyLogos />
            <PictureReviews />
            <Numbers />
            <HowItWorks />
            <Pricing
                handleCTA={handlePopupCheckout}
                setIsPricingInView={setIsPricingInView}
            />
            <NewFeature />
            {/* <Testimonials /> */}
            <NewReviews onImageLoad={toggleRender} />
            {!isMobile && <Tools />}
            {/* <ChatGPTSection /> */}
            <Faq />
            <Institutions />
            {/* <Features /> */}
            <CTA
                handleCTA={handlePopupCheckout}
                setIsPricingInView={setIsPricingInView}
            />
            <Footer scrollToPricing={scrollToPricing} />
            <StickyPricing
                showCheckout={showPopupCheckout}
                handleCTA={handlePopupCheckout}
                isPricingInView={isPricingInView}
            />
            <div
                className={`popup-checkout ${
                    showPopupCheckout ? 'active' : ''
                } ${checkoutPage ? 'checkout-page' : ''}`}
            >
                {checkoutPage ? (
                    <PaymentDetails isPopupCheckout />
                ) : (
                    <Register
                        isPopupCheckout
                        handleCheckoutPage={handleCheckoutPage}
                    />
                )}
            </div>
            {showPopupCheckout && (
                <div
                    className="popup-checkout-overlay"
                    onClick={handlePopupCheckoutClose}
                ></div>
            )}
            {/* <DisturbancePopup
                show={disturbancePopup}
                handleClose={disturbancePopupCloseHandler}
            /> */}
            <TrialPopup
                show={isTrialPopupShow && !showPopupCheckout}
                handleClose={handleCloseTrialPopup}
                handleCTA={handlePopupCheckout}
            />
            <NewsletterPopup
                show={isNewsLetterPopupShow && !showPopupCheckout}
                handleClose={handleCloseNewsLetterPopup}
            />
        </div>
    );
};
